import { basicModuleList, additionalModuleList } from "@schemas/modules";

export default {
	schemaType: "template",
	displayName: "Blog Detail",
	component: "BlogDetail",
	dataPacks: ["BLOG"],
	type: { label: "BLOG", value: "BLOG", mode: "detail" },
	content: [
		{
			title: "Category",
			key: "blogCategory",
			type: "AsyncCheckGroup",
			source: "BLOG_CATEGORY",
			mandatory: true,
		},
		{
			title: "Tags",
			key: "blogTag",
			type: "AsyncCheckGroup",
			source: "BLOG_TAG",
			mandatory: false,
			hideable: true,
		},
		{
			title: "Blogs",
			type: "ReferenceField",
			sources: [{ structuredData: "BLOG" }],
			key: "data",
		},
		{
			title: "Image",
			type: "ImageField",
			key: "image",
			mandatory: true,
		},
		{
			title: "Main Title",
			type: "TextField",
			humanReadable: true,
			key: "mainTitle",
			mandatory: true,
		},
		{
			title: "Author",
			key: "blogAuthor",
			type: "AsyncSelect",
			source: "BLOG_AUTHOR",
		},
		{
			title: "Publication Date",
			type: "DateField",
			key: "publicationDate",
			selectsRange: true,
			mandatory: true,
			indexable: true,
		},
		{
			title: "Update Date",
			type: "DateField",
			selectsRange: true,
			key: "updateDate",
			indexable: true,
			hideable: true,
		},
		{
			title: "Subtitle",
			type: "TextField",
			humanReadable: true,
			key: "subtitle",
			mandatory: false,
			hideable: true,
		},
		{
			title: "Content",
			type: "Wysiwyg",
			humanReadable: true,
			key: "content",
			mandatory: true,
		},
		{
			title: "Main Section",
			type: "ComponentArray",
			maxItems: null,
			whiteList: [...basicModuleList, ...additionalModuleList],
			key: "mainSection",
		},
	],

	default: {
		type: "template",
		templateType: "BlogDetail",
		image: {
			alt: "",
			publicId: "CegDEV/CEG-HeroPhoto_93f16f65-d9b4-446b-b59f-11a8d320907e",
			url: "",
		},
		data: {
			mode: "manual",
			sources: [{ structuredData: "BLOG" }],
			fixed: [],
		},
		hasDistributorData: true,
		mainTitle: "Lorem Ipsum",
		publicationDate: "2021/01/01",
		subtitle:
			"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam lacus risus, fermentum vitae quam vel, semper malesuada magna. Fusce cursus tempus nunc, vitae suscipit turpis dictum id. Nulla orci neque, elementum nec magna a, placerat bibendum lectus.",
		content:
			"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam lacus risus, fermentum vitae quam vel, semper malesuada magna. Fusce cursus tempus nunc, vitae suscipit turpis dictum id. Nulla orci neque, elementum nec magna a, placerat bibendum lectus. Proin ac gravida nibh. Sed lectus nibh, eleifend ut iaculis non, venenatis at enim. Nulla dui magna, feugiat ut eleifend vitae, porttitor vel tellus. Mauris magna dolor, tincidunt vel leo quis, elementum bibendum est. Duis tincidunt ornare tincidunt. Donec pulvinar, ipsum et viverra varius, purus nunc facilisis arcu, a vulputate est eros ut purus. Proin vel arcu semper, semper elit eu, euismod quam. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Cras pretium, neque quis varius cursus, nisl elit feugiat nunc, sit amet efficitur metus libero vel nibh. Phasellus euismod massa at nulla aliquam, fermentum feugiat dolor elementum. ",
		mainSection: {
			component: "Section",
			name: "Main Section",
			modules: [
				{
					component: "SocialShare",
					title: "Share",
					theme: "defaultAlt",
				},
				{
					component: "CardCollection",
					title: { content: "Title", tag: "h2" },
					kind: "BasicCard",
					elements: [
						{
							component: "BasicCard",
							title: "Lorem ipsum",
							description:
								"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu. ",
							file: {
								alt: "",
								publicId:
									"CegDEV/CEG-HeroPhoto_93f16f65-d9b4-446b-b59f-11a8d320907e",
								url: "",
							},
							veil: 40,
							button: {
								component: "Button",
							},
						},
						{
							component: "BasicCard",
							title: "Lorem ipsum",
							description:
								"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu. ",
							file: {
								alt: "",
								publicId:
									"CegDEV/CEG-HeroPhoto_93f16f65-d9b4-446b-b59f-11a8d320907e",
								url: "",
							},
							veil: 40,
							button: {
								component: "Button",
							},
						},
					],
					button: {
						component: "Button",
						buttonText: "Link",
						appearance: "link",
						size: "small",
						url: {
							href: "",
							linkToURL: null,
							newTab: false,
							noFollow: false,
						},
					},
					layout: "L004",
				},
				{
					component: "CardCollection",
					title: { content: "Title", tag: "h2" },
					kind: "ImageCard",
					elements: [
						{
							component: "ImageCard",
							file: {
								publicId:
									"CegDEV/CEG-HeroPhoto_93f16f65-d9b4-446b-b59f-11a8d320907e",
							},
							name: "Lorem Ipsum",
							shortInfo: "Lorem Ipsum",
							degree: "Lorem ipsum dolor sit amet consectetur",
							title:
								"Rutrum praesent vel metus accumsan fames nam libero molestie egestas ridiculus.",
							description:
								"“ELorem ipsum dolor sit amet consectetur adipiscing elit hac vivamus, vel senectus ligula nostra dapibus ultrices accumsan est, metus a eleifend sociosqu quis urna id feugiat. Rutrum praesent vel metus accumsan fames nam libero molestie egestas ridiculus, vulputate sociis cras quisque sagittis quis scelerisque curae orci hac lobortis, ut pellentesque vehicula non nunc viverra malesuada porta torquent. Dapibus vestibulum purus ullamcorper parturient fermentum arcu phasellus netus egestas vel viverra, penatibus interdum semper dis himenaeos diam senectus justo laoreet dignissim, facilisis est aptent ante fringilla maecenas libero rhoncus praesent quam.”",
						},
						{
							component: "ImageCard",
							file: {
								publicId:
									"CegDEV/CEG-HeroPhoto_93f16f65-d9b4-446b-b59f-11a8d320907e",
							},
							name: "Lorem Ipsum",
							shortInfo: "Lorem Ipsum",
							degree: "Lorem ipsum dolor sit amet consectetur",
							title:
								"Rutrum praesent vel metus accumsan fames nam libero molestie egestas ridiculus.",
							description:
								"“ELorem ipsum dolor sit amet consectetur adipiscing elit hac vivamus, vel senectus ligula nostra dapibus ultrices accumsan est, metus a eleifend sociosqu quis urna id feugiat. Rutrum praesent vel metus accumsan fames nam libero molestie egestas ridiculus, vulputate sociis cras quisque sagittis quis scelerisque curae orci hac lobortis, ut pellentesque vehicula non nunc viverra malesuada porta torquent. Dapibus vestibulum purus ullamcorper parturient fermentum arcu phasellus netus egestas vel viverra, penatibus interdum semper dis himenaeos diam senectus justo laoreet dignissim, facilisis est aptent ante fringilla maecenas libero rhoncus praesent quam.”",
						},
					],
					layout: "L004",
					button: {
						component: "Button",
						buttonText: "Link",
						appearance: "link",
						size: "small",
						url: {
							href: "",
							linkTo: null,
							newTab: false,
							noFollow: false,
						},
					},
				},
				{
					component: "StickyFooterCTA",
					phoneNumber: "+34 91 514 53 30",
					button: {
						component: "Button",
						buttonText: "Solicita información",
						appearance: "primary",
						size: "medium",
						url: {
							href: "",
							linkTo: null,
							newTab: false,
							noFollow: false,
						},
					},
				},
			],
		},
	},

	thumbnails: {},
};

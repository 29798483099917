import CLOUDINARY_BASE_UPLOAD_URL from "@constants/cloudinary/base-upload-url";
import { instance } from "@instance-config";
import { createSchema } from "@utils/";

export default createSchema({
	schemaType: "module",
	displayName: "Inner Title",
	component: "InnerTitle",
	category: "featured",
	dataPacks: null,
	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "Background Image",
					type: "ComponentContainer",
					whiteList: ["Image"],
					key: "backgroundImage",
					helptext: "Recommended minimum image size: 2508x1030",
				},
				{
					title: "Title",
					type: "HeadingField",
					key: "title",
					mandatory: true,
					advanced: true,
					default: { tag: "h2", content: "Title" },
					options: [
						{ value: "h1", label: "H1" },
						{ value: "h2", label: "H2" },
						{ value: "h3", label: "H3" },
						{ value: "h4", label: "H4" },
						{ value: "span", label: "span" },
					],
				},
				{
					title: "Subtitle",
					type: "TextField",
					key: "subtitle",
					hideable: true,
				},
				{
					title: "Show Icon",
					type: "RadioGroup",
					key: "icon",
					options: [
						{
							value: true,
							title: "Yes",
							name: "yes",
						},
						{
							value: false,
							title: "No",
							name: "no",
						},
					],
				},
				{
					title: "Show breadcrumbs only",
					type: "RadioGroup",
					key: "breadcrumbsOnly",
					options: [
						{
							value: true,
							title: "Yes",
							name: "yes",
						},
						{
							value: false,
							title: "No",
							name: "no",
						},
					],
				},
			],
		},
		{
			title: "config",
			fields: [
				{
					title: "Style",
					key: "theme",
					type: "VisualUniqueSelection",
					mandatory: true,
					options: [
						{
							theme: "garrigues-theme",
							options: [
								{
									value: "default",
									img: `${CLOUDINARY_BASE_UPLOAD_URL}/themes/garrigues-theme/default.png`,
								},
							],
						},
						{
							theme: "eeg-theme",
							options: [
								{
									value: "default",
									img: `${CLOUDINARY_BASE_UPLOAD_URL}/themes/eeg-theme/default.png`,
								},
								{
									value: "defaultAlt",
									img: `${CLOUDINARY_BASE_UPLOAD_URL}/themes/eeg-theme/defaultAlt.png`,
								},
							],
						},
						{
							theme: "ipam-theme",
							options: [
								{
									value: "accent",
									img: `${CLOUDINARY_BASE_UPLOAD_URL}/themes/ipam-theme/accent.png`,
								},
								{
									value: "default",
									img: `${CLOUDINARY_BASE_UPLOAD_URL}/themes/ipam-theme/default.png`,
								},
								{
									value: "defaultAlt",
									img: `${CLOUDINARY_BASE_UPLOAD_URL}/themes/ipam-theme/defaultAlt.png`,
								},
							],
						},
						{
							theme: "iade-theme",
							options: [
								{
									value: "default",
									img: `${CLOUDINARY_BASE_UPLOAD_URL}/themes/iade-theme/default.png`,
								},
								{
									value: "defaultAlt",
									img: `${CLOUDINARY_BASE_UPLOAD_URL}/themes/iade-theme/defaultAlt.png`,
								},
								{
									value: "accent",
									img: `${CLOUDINARY_BASE_UPLOAD_URL}/themes/iade-theme/accent.png`,
								},
							],
						},
						{
							theme: "iade-es-theme",
							options: [
								{
									value: "default",
									img: `${CLOUDINARY_BASE_UPLOAD_URL}/themes/iade-es-theme/default.png`,
								},
								{
									value: "defaultAlt",
									img: `${CLOUDINARY_BASE_UPLOAD_URL}/themes/iade-es-theme/defaultAlt.png`,
								},
								{
									value: "accent",
									img: `${CLOUDINARY_BASE_UPLOAD_URL}/themes/iade-es-theme/accent.png`,
								},
							],
						},
					],
					columns: 8,
				},
			],
		},
	],

	default: {
		component: "InnerTitle",
		title: {
			content: "Rankings y Reconocimientos",
			tag: "h4",
		},
		subtitle:
			"El Centro de Estudios Garrigues es, un año más, nº1  en los principales rankings de postgrado nacionales.",
		backgroundImage: {
			component: "Image",
			alt: "Alt",
			file: {
				publicId: "CegDEV/CEG-HeroPhoto_93f16f65-d9b4-446b-b59f-11a8d320907e",
			},
			veil: 0,
		},
		icon: false,
		breadcrumbsOnly: false,
		theme:
			instance.includes("IPAM") || instance.includes("IADE")
				? "accent"
				: "default",
	},
	thumbnails: {
		"1x": `${CLOUDINARY_BASE_UPLOAD_URL}/thumbnails/InnerTitle_x2.png`,
		"2x": `${CLOUDINARY_BASE_UPLOAD_URL}/thumbnails/InnerTitle_x2.png`,
	},
});

import CLOUDINARY_BASE_UPLOAD_URL from "@constants/cloudinary/base-upload-url";
import { createSchema } from "@utils/index";

export default createSchema({
	schemaType: "component",
	displayName: "Button To Top",
	component: "ButtonToTop",
	dataPacks: null,
	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "Text",
					type: "TextField",
					key: "buttonText",
					mandatory: true,
					hedeable: false,
					humanReadable: true,
				},
			],
		},
		{
			title: "config",
			fields: [
				{
					title: "Appearance",
					key: "appearance",
					type: "VisualUniqueSelection",
					options: [
						{
							value: "primary",
							img: `${CLOUDINARY_BASE_UPLOAD_URL}/themes/primary.png`,
						},
						{
							value: "secondary",
							img: `${CLOUDINARY_BASE_UPLOAD_URL}/themes/secondary.png`,
						},
						{
							value: "link",
							img: `${CLOUDINARY_BASE_UPLOAD_URL}/themes/link.png`,
						},
					],
					columns: 3,
				},
			],
		},
	],

	default: {
		component: "ButtonToTop",
		buttonText: "Solicita tu admisión",
		appearance: "primary",
		size: "medium",
	},
});

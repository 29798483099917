import CLOUDINARY_BASE_UPLOAD_URL from "@constants/cloudinary/base-upload-url";
import { createSchema } from "@utils/index";

export default createSchema({
	schemaType: "module",
	displayName: "Featured Audiovisual",
	component: "FeaturedAudiovisual",
	category: "featured",
	dataPacks: null,
	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "Video Big",
					type: "ComponentContainer",
					whiteList: ["Video"],
					key: "videoBig",
					mandatory: true,
				},
				{
					title: "Video Small",
					type: "ComponentContainer",
					whiteList: ["Video"],
					key: "videoSmall",
					mandatory: true,
				},
				{
					title: "Description Card",
					type: "ComponentContainer",
					whiteList: ["DescriptionCard"],
					key: "descriptionCard",
					mandatory: true,
				},
			],
		},
		{
			title: "config",
			fields: [
				{
					title: "Style",
					key: "theme",
					type: "VisualUniqueSelection",
					mandatory: true,
					options: [
						{
							theme: "garrigues-theme",
							options: [
								{
									value: "defaultAlt",
									img: `${CLOUDINARY_BASE_UPLOAD_URL}/themes/garrigues-theme/defaultAlt.png`,
								},
							],
						},
						{
							theme: "eeg-theme",
							options: [
								{
									value: "default",
									img: `${CLOUDINARY_BASE_UPLOAD_URL}/themes/eeg-theme/default.png`,
								},
								{
									value: "defaultAlt",
									img: `${CLOUDINARY_BASE_UPLOAD_URL}/themes/eeg-theme/defaultAlt.png`,
								},
								{
									value: "accent",
									img: `${CLOUDINARY_BASE_UPLOAD_URL}/themes/eeg-theme/accent.png`,
								},
								{
									value: "accentAlt",
									img: `${CLOUDINARY_BASE_UPLOAD_URL}/themes/eeg-theme/accentAlt.png`,
								},
								{
									value: "accentLight",
									img: `${CLOUDINARY_BASE_UPLOAD_URL}/themes/eeg-theme/accentLight.png`,
								},
							],
						},
						{
							theme: "ipam-theme",
							options: [
								{
									value: "default",
									img: `${CLOUDINARY_BASE_UPLOAD_URL}/themes/ipam-theme/default.png`,
								},
								{
									value: "defaultAlt",
									img: `${CLOUDINARY_BASE_UPLOAD_URL}/themes/ipam-theme/defaultAlt.png`,
								},
								{
									value: "accent",
									img: `${CLOUDINARY_BASE_UPLOAD_URL}/themes/ipam-theme/accent.png`,
								},
								{
									value: "accentAlt",
									img: `${CLOUDINARY_BASE_UPLOAD_URL}/themes/ipam-theme/accentAlt.png`,
								},
								{
									value: "accentLight",
									img: `${CLOUDINARY_BASE_UPLOAD_URL}/themes/ipam-theme/accentLight.png`,
								},
							],
						},
						{
							theme: "iade-theme",
							options: [
								{
									value: "default",
									img: `${CLOUDINARY_BASE_UPLOAD_URL}/themes/iade-theme/default.png`,
								},
								{
									value: "defaultAlt",
									img: `${CLOUDINARY_BASE_UPLOAD_URL}/themes/iade-theme/defaultAlt.png`,
								},
								{
									value: "accent",
									img: `${CLOUDINARY_BASE_UPLOAD_URL}/themes/iade-theme/accent.png`,
								},
								{
									value: "accentAlt",
									img: `${CLOUDINARY_BASE_UPLOAD_URL}/themes/iade-theme/accentAlt.png`,
								},
								{
									value: "accentLight",
									img: `${CLOUDINARY_BASE_UPLOAD_URL}/themes/iade-theme/accentLight.png`,
								},
							],
						},
						{
							theme: "iade-es-theme",
							options: [
								{
									value: "default",
									img: `${CLOUDINARY_BASE_UPLOAD_URL}/themes/iade-es-theme/default.png`,
								},
								{
									value: "defaultAlt",
									img: `${CLOUDINARY_BASE_UPLOAD_URL}/themes/iade-es-theme/defaultAlt.png`,
								},
							],
						},
					],
					columns: 8,
				},
			],
		},
	],

	default: {
		component: "FeaturedAudiovisual",
		videoBig: {
			component: "Video",
			thumbnail: {
				component: "Image",
				file: {
					publicId: "CegDEV/CEG-HeroPhoto_93f16f65-d9b4-446b-b59f-11a8d320907e",
				},
				veil: 40,
			},
			url: "",
		},
		videoSmall: {
			component: "Video",
			thumbnail: {
				component: "Image",
				file: {
					publicId: "CegDEV/CEG-HeroPhoto_93f16f65-d9b4-446b-b59f-11a8d320907e",
				},
				veil: 40,
			},
			url: "",
		},
		descriptionCard: {
			component: "DescriptionCard",
			title: {
				content: "Description Card Title",
				tag: "h4",
			},
			description: "Lorem ipsum dolor sit amet",
			button: {
				component: "Button",
				buttonText: "Ver detalle",
				appearance: "link",
				size: "small",
				url: {
					href: "",
					linkTo: null,
					newTab: false,
					noFollow: false,
				},
			},
		},
		theme: "defaultAlt",
	},
	thumbnails: {
		"1x": `${CLOUDINARY_BASE_UPLOAD_URL}/thumbnails/FeaturedAudiovisual_x2.png`,
		"2x": `${CLOUDINARY_BASE_UPLOAD_URL}/thumbnails/FeaturedAudiovisual_x2.png`,
	},
});

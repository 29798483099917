import { instance } from "@instance-config";
import mountSchema from "@schemas/commons/MountSchema";

const isIPAM = instance.includes("IPAM");
const isIADE = instance.includes("IADE");
const isIADEES = instance.includes("iadeES");

const FieldsQuickFacts = {
	content: {
		location: {
			title: "Location",
			type: "AsyncCheckGroup",
			source: "LOCATION",
			key: "location",
		},
		language: {
			title: "Language",
			type: "AsyncCheckGroup",
			source: "LANGUAGE",
			key: "language",
		},
		courseMode: {
			title: "Course Mode",
			type: "AsyncCheckGroup",
			source: "COURSE_MODE",
			key: "courseMode",
			mandatory: true,
		},
		start: {
			title: "Start",
			type: "TextField",
			humanReadable: true,
			key: "start",
		},
		learningOption: {
			title: "Learning option",
			type: "TextField",
			humanReadable: true,
			key: "learningOption",
		},
		dedication: {
			title: "Dedication",
			type: "RichText",
			humanReadable: true,
			key: "dedication",
			mandatory: true,
			helptext: "Structured data (full time/part time)",
		},
		credits: {
			title: "Credits",
			type: "TextField",
			humanReadable: true,
			key: "credits",
		},
	},
	defaults: {
		location: [],
		language: [],
		courseMode: [],
		start: null,
		learningOption: [],
		credits: null,
		programType: [],
		dedication: null,
	},
};

const getFields = ({ hideable }) => {
	const _fields = {
		courseMode: { ...FieldsQuickFacts.content.courseMode },
		start: { ...FieldsQuickFacts.content.start },
		learningOption: { ...FieldsQuickFacts.content.learningOption },
		dedication: { ...FieldsQuickFacts.content.dedication },
		credits: { ...FieldsQuickFacts.content.credits },
	};

	if (hideable.length > 0) {
		hideable.forEach((field) => {
			_fields[field].hideable = true;
			_fields[field].madatory = false;
		});
	}

	const fields = [
		{ ..._fields.courseMode },
		{ ..._fields.start },
		{ ..._fields.learningOption },
		{ ..._fields.dedication },
		{ ..._fields.credits },
	];

	if (!!isIADE || !!isIPAM || isIADEES) {
		fields.push({ ...FieldsQuickFacts.content.location });
		fields.push({ ...FieldsQuickFacts.content.language });
	}

	return fields;
};

const getDefaults = () => {
	const defaults = {};
	defaults.courseMode = FieldsQuickFacts.defaults.courseMode;
	defaults.start = FieldsQuickFacts.defaults.start;
	defaults.learningOption = FieldsQuickFacts.defaults.learningOption;
	defaults.dedication = FieldsQuickFacts.defaults.dedication;
	defaults.credits = FieldsQuickFacts.defaults.credits;

	if (!!isIADE || !!isIPAM || isIADEES) {
		defaults.location = FieldsQuickFacts.defaults.location;
		defaults.language = FieldsQuickFacts.defaults.language;
	}

	return defaults;
};

const mountSchemaAndQuickFactsFields = ({ schemaBase, hideable = [] }) => {
	const fields = getFields({ hideable });
	const defaults = getDefaults();

	return mountSchema({ schemaBase, fields, defaults });
};

export default mountSchemaAndQuickFactsFields;

import CLOUDINARY_BASE_UPLOAD_URL from "@constants/cloudinary/base-upload-url";
import createThemeStyles from "@schemas/commons/getStyles";
import { createSchema } from "@utils/index";

export default createSchema({
	schemaType: "module",
	displayName: "Study Agregator",
	component: "StudyAgregator",
	category: "collections",
	dataPacks: null,
	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "Title",
					type: "HeadingField",
					humanReadable: true,
					key: "title",
					advanced: true,
					mandatory: false,
					hideable: true,
					default: { tag: "h2", content: "Title" },
					options: [
						{ value: "h1", label: "H1" },
						{ value: "h2", label: "H2" },
						{ value: "h3", label: "H3" },
						{ value: "h4", label: "H4" },
						{ value: "span", label: "span" },
					],
				},

				{
					title: "Cards",
					type: "ComponentArray",
					mandatory: true,
					minItems: 2,
					key: "elements",
					whiteList: ["StudyAgregatorCard"],
				},
			],
		},
		{
			title: "config",
			fields: [
				{
					title: "Layout",
					key: "layout",
					type: "VisualUniqueSelection",
					options: [
						{
							value: "L001",
							img: `${CLOUDINARY_BASE_UPLOAD_URL}/layouts/AwardsDistributor/layout001_x2.png`,
						},
						/*  {
              value: 'L002',
              img: `${CLOUDINARY_BASE_UPLOAD_URL}/layouts/AwardsDistributor/layout002_x2.png`,
            }, */
					],
					columns: 2,
				},
				{
					title: "Style",
					key: "theme",
					type: "VisualUniqueSelection",
					mandatory: true,
					options: [
						{
							...createThemeStyles("garrigues-theme", [
								"default",
								"defaultAlt",
							]),
						},
						{ ...createThemeStyles("eeg-theme", ["default", "defaultAlt"]) },
						{ ...createThemeStyles("ipam-theme", ["default", "defaultAlt"]) },
						{ ...createThemeStyles("iade-theme", ["default", "defaultAlt"]) },
						{
							...createThemeStyles("iade-es-theme", ["default", "defaultAlt"]),
						},
					],
					columns: 8,
				},
			],
		},
	],

	default: {
		component: "StudyAgregator",
		title: { tag: "h2", content: "Title" },
		elements: [
			{
				component: "StudyAgregatorCard",
				theme: "default",
				title: "Lorem ipsum dolor sit amet",
				subtitle: "Lorem ipsum dolor",
				image: {
					alt: "",
					publicId: "CegDEV/CEG-HeroPhoto_93f16f65-d9b4-446b-b59f-11a8d320907e",
				},
				componentModules: [
					{
						component: "StudyAgregatorElement",
						theme: "default",
						title: "Máster Executive en Asesoría Jurídica Empresarial",
						date: "Octubre 2022",
						site: "Presencial",
						time: "Full Time",
						credits: "90 ECTS",
						button: {
							component: "ButtonToTop",
							buttonText: "Me interesa",
							appearance: "primary",
							size: "medium",
						},
					},
					{
						component: "StudyAgregatorElement",
						theme: "default",
						title: "Máster Executive en Asesoría Jurídica Empresarial",
						date: "Octubre 2022",
						site: "Presencial",
						time: "Full Time",
						credits: "90 ECTS",
						button: {
							component: "ButtonToTop",
							buttonText: "Me interesa",
							appearance: "primary",
							size: "medium",
						},
					},
				],
			},
			{
				component: "StudyAgregatorCard",
				theme: "default",
				title: "Lorem ipsum dolor sit amet",
				subtitle: "Lorem ipsum dolor",
				image: {
					alt: "",
					publicId: "CegDEV/CEG-HeroPhoto_93f16f65-d9b4-446b-b59f-11a8d320907e",
				},
				componentModules: [
					{
						component: "StudyAgregatorElement",
						theme: "default",
						title: "Máster Executive en Asesoría Jurídica Empresarial",
						date: "Octubre 2022",
						site: "Presencial",
						time: "Full Time",
						credits: "90 ECTS",
						button: {
							component: "ButtonToTop",
							buttonText: "Me interesa",
							appearance: "primary",
							size: "medium",
						},
					},
					{
						component: "StudyAgregatorElement",
						theme: "default",
						title: "Máster Executive en Asesoría Jurídica Empresarial",
						date: "Octubre 2022",
						site: "Presencial",
						time: "Full Time",
						credits: "90 ECTS",
						button: {
							component: "ButtonToTop",
							buttonText: "Me interesa",
							appearance: "primary",
							size: "medium",
						},
					},
				],
			},
		],
		layout: "L001",
		theme: "default",
	},
	thumbnails: {
		"1x": `${CLOUDINARY_BASE_UPLOAD_URL}/thumbnails/StudyAgregator_x2.png`,
		"2x": `${CLOUDINARY_BASE_UPLOAD_URL}/thumbnails/StudyAgregator_x2.png`,
	},
});

import { optionsFilter } from "@schemas/commons/StudyFilters";
import { additionalModuleList, basicModuleList } from "@schemas/modules";

export default {
	schemaType: "template",
	displayName: "Study List",
	component: "StudyList",
	dataPacks: ["STUDY"],
	type: { label: "Study", value: "study", mode: "list" },
	content: [
		{
			title: "Hero Section",
			type: "ComponentArray",
			maxItems: 1,
			whiteList: ["HeroHome", "HeroStudy", "InnerTitle"],
			key: "heroSection",
		},
		{
			title: "Title",
			type: "HeadingField",
			humanReadable: true,
			key: "studyTitle",
			advanced: true,
			hideable: true,
			default: { tag: "h2", content: "Title" },
			options: [
				{ value: "h1", label: "H1" },
				{ value: "h2", label: "H2" },
				{ value: "h3", label: "H3" },
				{ value: "h4", label: "H4" },
				{ value: "span", label: "span" },
			],
		},
		{
			title: "Description",
			type: "TextField",
			humanReadable: true,
			key: "description",
			mandatory: false,
			hideable: true,
		},
		{
			title: "Filters",
			type: "CheckGroup",
			key: "filters",
			hideable: true,
			options: optionsFilter(),
		},
		{
			title: "Items per page",
			type: "NumberField",
			key: "itemsPerPage",
			mandatory: true,
		},
		{
			title: "Study",
			type: "ReferenceField",
			sources: [{ structuredData: "STUDY" }],
			key: "data",
			quantity: 0,
		},
		{
			title: "Main Section",
			type: "ComponentArray",
			maxItems: null,
			whiteList: [...additionalModuleList, ...basicModuleList],
			key: "mainSection",
		},
	],

	default: {
		type: "template",
		templateType: "StudyList",
		heroSection: {
			component: "Section",
			name: "Hero Section",
			modules: [
				{
					component: "InnerTitle",
					title: {
						content: "Title",
						tag: "h4",
					},
					subtitle: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
					backgroundImage: {
						component: "Image",
						alt: "Alt",
						file: {
							publicId:
								"CegDEV/CEG-HeroPhoto_93f16f65-d9b4-446b-b59f-11a8d320907e",
						},
						veil: 0,
					},
					icon: false,
					breadcrumbsOnly: false,
				},
			],
		},
		studyTitle: { content: "Title", tag: "h2" },
		description: "Lorem Ipsum",
		filters: "",
		data: {
			mode: "auto",
			sources: [{ structuredData: "STUDY" }],
			order: "alpha",
			quantity: 0,
			allLanguages: true,
			preferenceLanguage: true,
		},
		itemsPerPage: 6,
		hasDistributorData: true,
		mainSection: {
			component: "Section",
			name: "Main Section",
			modules: [
				{
					component: "CardCollection",
					title: { content: "Title", tag: "h2" },
					kind: "BasicCard",
					elements: [
						{
							component: "BasicCard",
							title: "Lorem ipsum",
							description:
								"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu. ",
							file: {
								alt: "",
								publicId:
									"CegDEV/CEG-HeroPhoto_93f16f65-d9b4-446b-b59f-11a8d320907e",
								url: "",
							},
							veil: 0,
							button: {
								component: "Button",
								buttonText: "Link",
								appearance: "link",
								size: "medium",
								url: {
									href: "",
									linkTo: null,
									newTab: false,
									noFollow: false,
								},
							},
						},
						{
							component: "BasicCard",
							title: "Lorem ipsum",
							description:
								"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu. ",
							file: {
								alt: "",
								publicId:
									"CegDEV/CEG-HeroPhoto_93f16f65-d9b4-446b-b59f-11a8d320907e",
								url: "",
							},
							veil: 0,
							button: {
								component: "Button",
								buttonText: "Link",
								appearance: "link",
								size: "medium",
								url: {
									href: "",
									linkTo: null,
									newTab: false,
									noFollow: false,
								},
							},
						},
					],
					layout: "L004",
				},
				{
					component: "EventDistributor",
					title: { content: "Title", tag: "h2" },
					kind: "EventCard",
					elements: [
						{
							component: "EventCard",
							dateTime: "",
							title: {
								content: "Event Card Title",
								tag: "h4",
							},
							description: "Lorem ipsum dolor sit amet",
							startsDateTime: "00:00",
							button: {
								component: "Button",
								buttonText: "Ver detalle",
								appearance: "link",
								size: "small",
								url: {
									href: "",
									linkTo: null,
									newTab: false,
									noFollow: false,
								},
							},
							secondaryButton: {
								component: "Button",
								buttonText: "Inscribirme",
								appearance: "secondary",
								size: "small",
								url: {
									href: "",
									linkTo: null,
									newTab: false,
									noFollow: false,
								},
							},
						},
						{
							component: "EventCard",
							dateTime: "",
							title: {
								content: "Event Card Title",
								tag: "h4",
							},
							description: "Lorem ipsum dolor sit amet",
							startsDateTime: "00:00",
							button: {
								component: "Button",
								buttonText: "Ver detalle",
								appearance: "link",
								size: "small",
								url: {
									href: "",
									linkTo: null,
									newTab: false,
									noFollow: false,
								},
							},
							secondaryButton: {
								component: "Button",
								buttonText: "Inscribirme",
								appearance: "secondary",
								size: "small",
								url: {
									href: "",
									linkTo: null,
									newTab: false,
									noFollow: false,
								},
							},
						},
						{
							component: "EventCard",
							dateTime: "",
							title: {
								content: "Event Card Title",
								tag: "h4",
							},
							description: "Lorem ipsum dolor sit amet",
							startsDateTime: "00:00",
							button: {
								component: "Button",
								buttonText: "Ver detalle",
								appearance: "link",
								size: "small",
								url: {
									href: "",
									linkTo: null,
									newTab: false,
									noFollow: false,
								},
							},
							secondaryButton: {
								component: "Button",
								buttonText: "Inscribirme",
								appearance: "secondary",
								size: "small",
								url: {
									href: "",
									linkTo: null,
									newTab: false,
									noFollow: false,
								},
							},
						},
					],
					button: {
						component: "Button",
						buttonText: "Ver todos los eventos",
						appearance: "link",
						size: "small",
						url: {
							href: "",
							linkTo: null,
							newTab: false,
							noFollow: false,
						},
					},
					layout: "L001",
				},
				{
					component: "AddressCollection",
					title: { content: "Title", tag: "h3" },
					subtitle: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
					address: "Lorem ipsum dolor sit amet",
					email: "email@email.com",
					phone: "+34 (0) 00 00 00 00 00",
					button: {
						component: "Button",
						buttonText: "Cómo Llegar",
						appearance: "secondary",
						size: "big",
						url: {
							href: "",
							linkTo: null,
							newTab: false,
							noFollow: false,
						},
					},
					backgroundImage: {
						component: "Image",
						alt: "Alt",
						file: {
							publicId:
								"CegDEV/CEG-HeroPhoto_93f16f65-d9b4-446b-b59f-11a8d320907e",
						},
						veil: 0,
					},
				},
				{
					component: "StickyFooterCTA",
					phoneNumber: "+34 91 514 53 30",
					button: {
						component: "Button",
						buttonText: "Solicita información",
						appearance: "primary",
						size: "medium",
						url: {
							href: "",
							linkTo: null,
							newTab: false,
							noFollow: false,
						},
					},
				},
			],
		},
	},

	thumbnails: {},
};

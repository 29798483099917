import CLOUDINARY_BASE_UPLOAD_URL from "@constants/cloudinary/base-upload-url";
import { instance } from "@instance-config";
import createThemeStyles from "@schemas/commons/getStyles";
import { createSchema } from "@utils/";

const isIADE = instance.includes("IADE");
const isIPAM = instance.includes("IPAM");
const isIADEEs = instance.includes("iadeEs");

export default createSchema({
	schemaType: "module",
	displayName: "Study Staff",
	component: "StudyStaff",
	category: "people",
	dataPacks: ["STAFF"],
	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "Title",
					type: "HeadingField",
					humanReadable: true,
					key: "title",
					advanced: true,
					hideable: false,
					default: { tag: "h2", content: "Title" },
					options: [
						{ value: "h1", label: "H1" },
						{ value: "h2", label: "H2" },
						{ value: "h3", label: "H3" },
						{ value: "h4", label: "H4" },
						{ value: "span", label: "span" },
					],
				},
				{
					title: "Description",
					type: "RichText",
					key: "description",
					mandatory: true,
					humanReadable: true,
				},
				{
					title: "Staff",
					type: "ReferenceField",
					sources: [{ structuredData: "STAFF" }],
					key: "data",
				},
				{
					title: "Accordion",
					type: "ComponentContainer",
					maxItems: null,
					mandatory: false,
					hideable: true,
					key: "accordion",
					whiteList: ["Accordion"],
				},
			],
		},
		{
			title: "config",
			fields: [
				{
					title: "Style",
					key: "theme",
					type: "VisualUniqueSelection",
					mandatory: true,
					options: [
						{
							...createThemeStyles("garrigues-theme", [
								'default", "defaultAlt',
							]),
						},
						{ ...createThemeStyles("eeg-theme", ["default"]) },
						{ ...createThemeStyles("ipam-theme", ["accent", "defaultAlt"]) },
						{ ...createThemeStyles("iade-theme", ["accent"]) },
						{ ...createThemeStyles("iade-es-theme", ["defaultAlt"]) },
					],
					columns: 8,
				},
			],
		},
	],

	default: {
		component: "StudyStaff",
		title: { content: "Title", tag: "h2" },
		description: "",
		data: {
			mode: "auto",
			sources: [{ structuredData: "STAFF" }],
			order: "alpha",
			quantity: 15,
		},
		accordion: {
			component: "Accordion",
			title: null,
			theme: "default",
			elements: [
				{
					component: "AccordionElement",
					title: { content: "Title", tag: "h3" },
					componentModules: [
						{
							component: "CardCollection",
							title: { content: "Title", tag: "h2" },
							subtitle: "Lorem Ipsum",
							kind: "BasicCard",
							elements: [
								{
									component: "BasicCard",
									title: "Lorem ipsum",
									description:
										"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu. ",
									file: {
										alt: "",
										publicId:
											"CegDEV/CEG-HeroPhoto_93f16f65-d9b4-446b-b59f-11a8d320907e",
										url: "",
									},
									veil: 0,
									button: {
										component: "Button",
										buttonText: "Link",
										appearance: "link",
										size: "medium",
										url: {
											href: "",
											linkTo: null,
											newTab: false,
											noFollow: false,
										},
									},
								},
							],
							button: {
								component: "Button",
								buttonText: "Link",
								appearance: "link",
								size: "small",
								url: {
									href: "",
									linkTo: null,
									newTab: false,
									noFollow: false,
								},
							},
							layout: "L001",
						},
					],
				},
			],
		},
		hasDistributorData: true,
		theme: isIADEEs
			? "defaultAlt"
			: isIADE
			? "accent"
			: isIPAM
			? "accent"
			: "default",
	},
	thumbnails: {
		"1x": `${CLOUDINARY_BASE_UPLOAD_URL}/thumbnails/StudyStaff_x2.png`,
		"2x": `${CLOUDINARY_BASE_UPLOAD_URL}/thumbnails/StudyStaff_x2.png`,
	},
});

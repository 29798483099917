import CLOUDINARY_BASE_UPLOAD_URL from "@constants/cloudinary/base-upload-url";
import createThemeStyles from "@schemas/commons/getStyles";
import { createSchema } from "@utils/index";

export const schema = {
	schemaType: "module",
	displayName: "News Distributor",
	component: "NewsDistributor",
	dataPacks: ["NEWS"],
	category: "articlesAndEvents",
	// sectionList: allSectionList,
	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "Title",
					type: "HeadingField",
					humanReadable: true,
					key: "title",
					advanced: true,
					hideable: true,
					default: { tag: "h2", content: "Title" },
					options: [
						{ value: "h1", label: "H1" },
						{ value: "h2", label: "H2" },
						{ value: "h3", label: "H3" },
						{ value: "h4", label: "H4" },
						{ value: "span", label: "span" },
					],
				},
				{
					title: "News",
					type: "ReferenceField",
					sources: [{ structuredData: "NEWS" }],
					key: "data",
				},
				{
					title: "Grouping Link",
					type: "ComponentContainer",
					whiteList: ["Button"],
					key: "button",
					hideable: true,
				},
			],
		},
		{
			title: "config",
			fields: [
				{
					title: "Layout",
					key: "layout",
					type: "VisualUniqueSelection",
					options: [
						{
							value: "simple",
							img: `${CLOUDINARY_BASE_UPLOAD_URL}/layouts/NewsDistributor/layout001.png`,
						},
						{
							value: "featuredNews",
							img: `${CLOUDINARY_BASE_UPLOAD_URL}/layouts/NewsDistributor/layout002.png`,
						},
					],
					columns: 2,
				},
				{
					title: "Element style",
					key: "elementStyle",
					type: "RadioGroup",
					helptext: "",
					options: [
						{
							name: "magazineCard",
							value: "magazineCard",
							title: "Magazine Card",
						},
						{
							name: "magazineCardReduced",
							value: "magazineCardReduced",
							title: "Magazine Card Reduced",
						},
					],
				},
				{
					title: "Style",
					key: "theme",
					type: "VisualUniqueSelection",
					mandatory: true,
					options: [
						{ ...createThemeStyles("garrigues-theme", ["default"]) },
						{ ...createThemeStyles("eeg-theme", ['default", "defaultAlt']) },
						{ ...createThemeStyles("ipam-theme", ['default", "defaultAlt']) },
						{ ...createThemeStyles("iade-theme", ["default", "defaultAlt"]) },
						{
							...createThemeStyles("iade-es-theme", ["default", "defaultAlt"]),
						},
					],
					columns: 8,
				},
			],
		},
	],

	default: {
		component: "NewsDistributor",
		title: {
			content: "News distributor title",
			tag: "h2",
		},
		data: {
			mode: "auto",
			sources: [{ structuredData: "NEWS" }],
			order: "recent-ASC",
			quantity: 3,
		},
		button: {
			component: "Button",
			buttonText: "Ver más noticias",
			appearance: "link",
			size: "small",
			url: {
				href: "",
				linkTo: null,
				newTab: false,
				noFollow: false,
			},
		},
		layout: "simple",
		elementStyle: "magazineCard",
		hasDistributorData: true,
		theme: "default",
	},
	thumbnails: {
		"1x": `${CLOUDINARY_BASE_UPLOAD_URL}/thumbnails/NewsDistributor_x2.png`,
		"2x": `${CLOUDINARY_BASE_UPLOAD_URL}/thumbnails/NewsDistributor_x2.png`,
	},
};
export default createSchema(schema);

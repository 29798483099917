import CLOUDINARY_BASE_UPLOAD_URL from "@constants/cloudinary/base-upload-url";
import { instance } from "@instance-config";
import { createSchema } from "@utils/index";

export default createSchema({
	schemaType: "module",
	displayName: "Landing Event List",
	component: "LandingEventList",
	category: "collection",
	dataPacks: null,
	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "Title",
					type: "HeadingField",
					humanReadable: true,
					key: "title",
					advanced: true,
					hideable: true,
					default: { tag: "h2", content: "Default placeholder" },
					options: [
						{ value: "h1", label: "H1" },
						{ value: "h2", label: "H2" },
						{ value: "h3", label: "H3" },
						{ value: "h4", label: "H4" },
						{ value: "span", label: "span" },
					],
				},
				{
					title: "Event cards",
					type: "ComponentArray",
					whiteList: ["LandingEventCard"],
					key: "elements",
					maxItems: null,
					mandatory: true,
				},
			],
		},
		{
			title: "config",
			fields: [
				{
					title: "Style",
					key: "theme",
					type: "VisualUniqueSelection",
					mandatory: true,
					options: [
						{
							theme: "garrigues-theme",
							options: [
								{
									value: "default",
									img: `${CLOUDINARY_BASE_UPLOAD_URL}/themes/garrigues-theme/default.png`,
								},
								{
									value: "defaultAlt",
									img: `${CLOUDINARY_BASE_UPLOAD_URL}/themes/garrigues-theme/defaultAlt.png`,
								},
							],
						},
						{
							theme: "ipam-theme",
							options: [
								{
									value: "defaultAlt",
									img: `${CLOUDINARY_BASE_UPLOAD_URL}/themes/ipam-theme/defaultAlt.png`,
								},
							],
						},
						{
							theme: "iade-theme",
							options: [
								{
									value: "default",
									img: `${CLOUDINARY_BASE_UPLOAD_URL}/themes/iade-theme/default.png`,
								},
								{
									value: "defaultAlt",
									img: `${CLOUDINARY_BASE_UPLOAD_URL}/themes/iade-theme/defaultAlt.png`,
								},
							],
						},
						{
							theme: "iade-es-theme",
							options: [
								{
									value: "default",
									img: `${CLOUDINARY_BASE_UPLOAD_URL}/themes/iade-es-theme/default.png`,
								},
								{
									value: "defaultAlt",
									img: `${CLOUDINARY_BASE_UPLOAD_URL}/themes/iade-es-theme/defaultAlt.png`,
								},
							],
						},
					],
					columns: 8,
				},
			],
		},
	],
	default: {
		component: "LandingEventList",
		title: { content: "Title", tag: "h4" },
		elements: [
			{
				component: "LandingEventCard",
				title: { content: "Title", tag: "h3" },
				image: {
					alt: "",
					publicId: "CegDEV/CEG-HeroPhoto_93f16f65-d9b4-446b-b59f-11a8d320907e",
					url: "",
				},
				eventDate: "2021/01/01",
				eventHour: "00:00",
				subtitle:
					"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu. ",
				description:
					"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu. ",
				idCampaign: "",
				button: {
					component: "Button",
					buttonText: "Leer más",
					url: {
						href: "",
					},
					appearance: "primary",
					size: "big",
				},
			},
			{
				component: "LandingEventCard",
				title: { content: "Title", tag: "h3" },
				image: {
					alt: "",
					publicId: "CegDEV/CEG-HeroPhoto_93f16f65-d9b4-446b-b59f-11a8d320907e",
					url: "",
				},
				eventDate: "2021/01/01",
				eventHour: "00:00",
				subtitle:
					"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu. ",
				description:
					"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu. ",
				idCampaign: "",
				button: {
					component: "Button",
					buttonText: "Leer más",
					url: {
						href: "",
					},
					appearance: "primary",
					size: "big",
				},
			},
			{
				component: "LandingEventCard",
				title: { content: "Title", tag: "h3" },
				image: {
					alt: "",
					publicId: "CegDEV/CEG-HeroPhoto_93f16f65-d9b4-446b-b59f-11a8d320907e",
					url: "",
				},
				eventDate: "2021/01/01",
				eventHour: "00:00",
				subtitle:
					"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu. ",
				description:
					"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu. ",
				idCampaign: "",
				button: {
					component: "Button",
					buttonText: "Leer más",
					url: {
						href: "",
					},
					appearance: "primary",
					size: "big",
				},
			},
		],
		theme: instance.includes("IPAM") ? "defaultAlt" : "default",
	},
	thumbnails: {
		"1x": `${CLOUDINARY_BASE_UPLOAD_URL}/thumbnails/EventDistributor_x2.png`,
		"2x": `${CLOUDINARY_BASE_UPLOAD_URL}/thumbnails/EventDistributor_x2.png`,
	},
});

import thumbnail from "@axthumbs/templates/GenericInfoLanding.png";
import { artDirectionImageHorizontalVerticalDefaults } from "@schemas/components/ArtDirectionImageHorizontalVertical";
import { landingModuleList } from "@schemas/modules";

import TypologySelect from "../commons/TypologySelect";

export default {
	schemaType: "template",
	displayName: "Info Landing",
	component: "LandingInfoPortugal",
	dataPacks: ["LANDINGS"],
	category: "Landings",
	type: {
		label: "Landings",
		value: "landings",
		mode: "detail",
	},
	content: [
		{
			title: "Hero Section",
			type: "ComponentArray",
			maxItems: 1,
			whiteList: ["HeroLanding"],
			key: "heroSection",
			mandatory: true,
		},
		{
			title: "Tipología",
			type: "AsyncSelect",
			key: "typology",
			mandatory: true,
			source: ["LANDING_TYPE"],
		},
		{
			title: "Form type",
			type: "ComponentArray",
			whiteList: [
				"FormTypeGenericInfoLanding",
				"FormTypeProductLanding",
				"FormTypeDualProductLanding",
				"FormTypeAreaLanding",
				"FormTypeDualAreaLanding",
				"FormTypeOtherInfoLanding",
			],
			key: "formType",
			maxItems: 1,
			mandatory: true,
		},
		{
			title: "Main Section",
			type: "ComponentArray",
			maxItems: null,
			whiteList: [...landingModuleList],
			key: "mainSection",
		},
	],

	default: {
		type: "template",
		templateType: "LandingInfoPortugal",
		heroSection: {
			component: "Section",
			name: "Hero Section",
			modules: [
				{
					component: "HeroLanding",
					theme: "default",
					title: { content: "Title", tag: "h1" },
					backgroundImage: artDirectionImageHorizontalVerticalDefaults,
				},
			],
			sectionPosition: 1,
		},
		mainSection: {
			component: "Section",
			name: "Main Section",
			modules: [],
		},
		formType: {
			component: "Section",
			name: "formType",
			modules: [],
		},
		typology: TypologySelect.typology,
	},
	thumbnails: {
		"1x": thumbnail,
		"2x": thumbnail,
	},
};

import CLOUDINARY_BASE_UPLOAD_URL from "@constants/cloudinary/base-upload-url";
import { instance } from "@instance-config";
import createThemeStyles from "@schemas/commons/getStyles";
import { createSchema } from "@utils/";

const isIADE = instance.includes("IADE");
const isIPAM = instance.includes("IPAM");
const isIADEES = instance.includes("IADEES");

export default createSchema({
	schemaType: "module",
	displayName: "Video Playlist",
	component: "VideoPlaylist",
	category: "interactive",
	dataPacks: null,
	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "Title",
					type: "HeadingField",
					humanReadable: true,
					key: "title",
					mandatory: false,
					hideable: true,
					advanced: true,
					default: { tag: "h2", content: "Title" },
					options: [
						{ value: "h1", label: "H1" },
						{ value: "h2", label: "H2" },
						{ value: "h3", label: "H3" },
						{ value: "h4", label: "H4" },
						{ value: "span", label: "span" },
					],
				},

				{
					title: "Type of Links",
					type: "ConditionalField",
					key: "typeLinks",
					required: true,
					options: [
						{
							value: true,
							title: "Individual video",
							name: "individualVideo",
						},
						{
							value: false,
							title: "Youtube playlist",
							name: "youtubePlaylist",
						},
					],
					fields: [
						{
							title: "Individual video elements",
							type: "ComponentArray",
							maxItems: null,
							whiteList: ["VideoTitle"],
							key: "elements",
							mandatory: true,
							condition: true,
						},
						{
							title: "Youtube Playlist",
							key: "urlPlaylist",
							type: "TextField",
							mandatory: true,
							condition: false,
						},
					],
				},
			],
		},
		{
			title: "config",
			fields: [
				{
					title: "Style",
					key: "theme",
					type: "VisualUniqueSelection",
					mandatory: true,
					options: [
						{ ...createThemeStyles("garrigues-theme", ["accentAlt"]) },
						{
							...createThemeStyles("eeg-theme", [
								"default",
								"defaultAlt",
								"accent",
								"accentAlt",
								"accentLight",
							]),
						},
						{
							...createThemeStyles("ipam-theme", ["accentAlt", "accentLight"]),
						},
						{
							...createThemeStyles("iade-theme", [
								"accent",
								"accentAlt",
								"accentLight",
							]),
						},
						{
							...createThemeStyles("iade-es-theme", [
								"default",
								"accent",
								"accentAlt",
							]),
						},
					],
					columns: 8,
				},
			],
		},
	],

	default: {
		component: "VideoPlaylist",
		title: {
			content: "Title",
			tag: "h2",
		},
		typeLinks: true,
		urlPlaylist: "",
		elements: [
			{
				component: "VideoTitle",
				title: "Lorem ipsum #1",
				subtitle:
					" Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu.",

				url: "",
				thumbnail: {
					component: "Image",
					file: {
						publicId:
							"CegDEV/CEG-HeroPhoto_93f16f65-d9b4-446b-b59f-11a8d320907e",
					},
				},
			},
			{
				component: "VideoTitle",
				title: "Lorem ipsum #2",
				subtitle:
					" Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu.",
				url: "",
				thumbnail: {
					component: "Image",
					file: {
						publicId:
							"CegDEV/CEG-HeroPhoto_93f16f65-d9b4-446b-b59f-11a8d320907e",
					},
				},
			},
			{
				component: "VideoTitle",
				title: "Lorem ipsum #3",
				subtitle:
					" Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu.",
				url: "",
				thumbnail: {
					component: "Image",
					file: {
						publicId:
							"CegDEV/CEG-HeroPhoto_93f16f65-d9b4-446b-b59f-11a8d320907e",
					},
				},
			},
			{
				component: "VideoTitle",
				title: "Lorem ipsum #4",
				subtitle:
					" Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu.",
				url: "",
				thumbnail: {
					component: "Image",
					file: {
						publicId:
							"CegDEV/CEG-HeroPhoto_93f16f65-d9b4-446b-b59f-11a8d320907e",
					},
				},
			},
			{
				component: "VideoTitle",
				title: "Lorem ipsum #5",
				subtitle:
					" Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu.",
				url: "",
				thumbnail: {
					component: "Image",
					file: {
						publicId:
							"CegDEV/CEG-HeroPhoto_93f16f65-d9b4-446b-b59f-11a8d320907e",
					},
				},
			},
		],

		theme: isIADE
			? "accent"
			: isIPAM
			? "accentLight"
			: isIADEES
			? "default"
			: "accentAlt",
	},
	thumbnails: {
		"1x": `${CLOUDINARY_BASE_UPLOAD_URL}/thumbnails/VideoPlaylist_x2.png`,
		"2x": `${CLOUDINARY_BASE_UPLOAD_URL}/thumbnails/VideoPlaylist_x2.png`,
	},
});

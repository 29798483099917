import { instance } from "@instance-config";
import { basicModuleList, additionalModuleList } from "@schemas/modules";

export default {
	schemaType: "template",
	displayName: "Staff Detail",
	component: "StaffDetail",
	dataPacks: ["STAFF"],
	type: { label: "Staff", value: "staff", mode: "detail" },
	content: [
		{
			title: "Hero Section",
			type: "ComponentArray",
			maxItems: 1,
			whiteList: ["HeroHome", "HeroStudy", "InnerTitle"],
			key: "heroSection",
		},
		{
			title: "Image",
			type: "ImageField",
			key: "image",
			hideable: true,
		},
		{
			title: "Staff Title",
			type: "HeadingField",
			humanReadable: true,
			key: "staffTitle",
			default: { tag: "h1", content: "Title" },
			options: [
				{ value: "h1", label: "H1" },
				{ value: "h2", label: "H2" },
				{ value: "h3", label: "H3" },
				{ value: "h4", label: "H4" },
				{ value: "span", label: "span" },
				{ value: "p", label: "p" },
			],
			advanced: true,
			mandatory: true,
		},
		{
			title: "Course",
			type: "TextField",
			humanReadable: true,
			key: "course",
			mandatory: true,
		},
		{
			title: "Information",
			type: "RichText",
			humanReadable: true,
			key: "info",
			mandatory: true,
		},
		{
			title: "Email",
			type: "TextField",
			key: "email",
			hideable: true,
		},
		{
			title: "Document Link",
			type: "UrlField",
			key: "documentLink",
			helptext: "Add link to a pdf or any document type",
			hideable: true,
		},
		{
			title: "Bio Title",
			type: "TextField",
			humanReadable: true,
			key: "bioTitle",
			mandatory: true,
		},
		{
			title: "Bio",
			type: "RichText",
			humanReadable: true,
			key: "bio",
			mandatory: true,
		},
		{
			title: "Short Bio",
			type: "RichText",
			humanReadable: true,
			key: "shortBio",
			mandatory: true,
		},
		...(instance.includes("CEG")
			? [
					{
						title: "Blog distributor for this author",
						type: "ComponentContainer",
						whiteList: ["BlogDistributor"],
						key: "blogDistributorContainer",
						hideable: true,
						helptext:
							"It is necessary to filter the blog entries by their corresponding author. If once filtered, the module is not visible, it's likely that the author doesn't have any published blog entries.",
					},
			  ]
			: []),
		{
			title: "Main Section",
			type: "ComponentArray",
			maxItems: null,
			whiteList: [...basicModuleList, ...additionalModuleList],
			key: "mainSection",
		},
	],
	default: {
		type: "template",
		templateType: "StaffDetail",
		heroSection: {
			component: "Section",
			name: "Hero Section",
			modules: [
				{
					component: "InnerTitle",
					title: {
						content: "Title",
						tag: "h1",
					},
					subtitle: "",
					backgroundImage: {
						component: "Image",
						alt: "Alt",
						file: {
							publicId:
								"CegDEV/CEG-HeroPhoto_93f16f65-d9b4-446b-b59f-11a8d320907e",
						},
						veil: 0,
					},
					icon: false,
					breadcrumbsOnly: true,
					theme: "default",
					size: "small",
				},
			],
		},
		image: {
			alt: "",
			publicId: "CegDEV/CEG-HeroPhoto_93f16f65-d9b4-446b-b59f-11a8d320907e",
			url: "",
		},
		staffTitle: { content: "Title", tag: "h1" },
		course: "Lorem ipsum ",
		info: "Lorem ipusm",
		email: "example@email.com",
		documentLink: {
			text: "Descargar PDF",
			href: "",
			external: true,
			newTab: false,
			noFollow: false,
			linkTo: null,
		},
		bioTitle: "Lorem Ipsum",
		bio: "Cu nam labores lobortis definiebas, ei aliquyam salutatus persequeris quo, cum eu nemore fierent dissentiunt. Per vero dolor id, vide democritum scribentur eu vim, pri erroribus temporibus ex. Euismod molestie offendit has no. Quo te semper invidunt quaestio, per vituperatoribus sadipscing ei, partem aliquyam sensibus in cum.",
		shortBio:
			"Cu nam labores lobortis definiebas, ei aliquyam salutatus persequeris quo, ",

		...(instance.includes("CEG")
			? {
					blogDistributorContainer: {
						component: "BlogDistributor",
						data: {
							mode: "auto",
							sources: [{ structuredData: "BLOG" }],
							order: "recent-ASC",
							quantity: 3,
							fullRelations: true,
						},

						hasDistributorData: true,
						isAuthorHidden: true,
					},
			  }
			: {}),

		mainSection: {
			component: "Section",
			name: "Main Section",
			modules: [
				{
					component: "StaffDistributor",
					title: { content: "Title", tag: "h2" },
					description: "Lorem Ipsum",
					elements: [
						{
							component: "TeacherCard",
							image: {
								alt: "",
								publicId:
									"CegDEV/CEG-HeroPhoto_93f16f65-d9b4-446b-b59f-11a8d320907e",
								url: "",
							},
							staffTitle: "Title",
							subtitle: "Lorem Ipsum",
							email: "example@email.com",
							documentLink: {
								text: "Descargar PDF",
								href: "",
								external: true,
								newTab: false,
								noFollow: false,
								linkTo: null,
							},
							button: {
								component: "Button",
								buttonText: "Ver perfil",
								appearance: "secondary",
								size: "medium",
								url: {
									href: "",
									linkTo: null,
									newTab: false,
									noFollow: false,
								},
							},
							bg: "ui04",
						},
						{
							component: "TeacherCard",
							image: {
								alt: "",
								publicId:
									"CegDEV/CEG-HeroPhoto_93f16f65-d9b4-446b-b59f-11a8d320907e",
								url: "",
							},
							title: "Title",
							subtitle: "Lorem Ipsum",
							email: "example@email.com",
							documentLink: {
								text: "Descargar PDF",
								href: "",
								external: true,
								newTab: false,
								noFollow: false,
								linkTo: null,
							},
							button: {
								component: "Button",
								buttonText: "Ver perfil",
								appearance: "secondary",
								size: "medium",
								url: {
									href: "",
									linkTo: null,
									newTab: false,
									noFollow: false,
								},
							},
							bg: "ui04",
						},
						{
							component: "TeacherCard",
							image: {
								alt: "",
								publicId:
									"CegDEV/CEG-HeroPhoto_93f16f65-d9b4-446b-b59f-11a8d320907e",
								url: "",
							},
							title: "Title",
							subtitle: "Lorem Ipsum",
							email: "example@email.com",
							documentLink: {
								text: "Descargar PDF",
								href: "",
								external: true,
								newTab: false,
								noFollow: false,
								linkTo: null,
							},
							button: {
								component: "Button",
								buttonText: "Ver perfil",
								appearance: "secondary",
								size: "medium",
								url: {
									href: "",
									linkTo: null,
									newTab: false,
									noFollow: false,
								},
							},
							bg: "ui04",
						},
					],
					layout: "L001",
				},
				{
					component: "CardCollection",
					title: { content: "Title", tag: "h2" },
					kind: "BasicCard",
					elements: [
						{
							component: "BasicCard",
							title: "Lorem ipsum",
							description:
								"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu. ",
							file: {
								alt: "",
								publicId:
									"CegDEV/CEG-HeroPhoto_93f16f65-d9b4-446b-b59f-11a8d320907e",
								url: "",
							},
							veil: 0,
							button: {
								component: "Button",
								buttonText: "Link",
								appearance: "link",
								size: "medium",
								url: {
									href: "",
									linkTo: null,
									newTab: false,
									noFollow: false,
								},
							},
						},
						{
							component: "BasicCard",
							title: "Lorem ipsum",
							description:
								"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu. ",
							file: {
								alt: "",
								publicId:
									"CegDEV/CEG-HeroPhoto_93f16f65-d9b4-446b-b59f-11a8d320907e",
								url: "",
							},
							veil: 0,
							button: {
								component: "Button",
								buttonText: "Link",
								appearance: "link",
								size: "medium",
								url: {
									href: "",
									linkTo: null,
									newTab: false,
									noFollow: false,
								},
							},
						},
					],
					layout: "L004",
				},
				{
					component: "CardCollection",
					title: { content: "Title", tag: "h2" },
					kind: "ImageCard",
					elements: [
						{
							component: "ImageCard",
							file: {
								alt: "",
								publicId:
									"CegDEV/CEG-HeroPhoto_93f16f65-d9b4-446b-b59f-11a8d320907e",
								url: "",
							},
							name: "Lorem Ipsum",
							shortInfo: "Lorem Ipsum",
							degree: "Lorem ipsum dolor sit amet consectetur",
							title:
								"Rutrum praesent vel metus accumsan fames nam libero molestie egestas ridiculus.",
							description:
								"“ELorem ipsum dolor sit amet consectetur adipiscing elit hac vivamus, vel senectus ligula nostra dapibus ultrices accumsan est, metus a eleifend sociosqu quis urna id feugiat. Rutrum praesent vel metus accumsan fames nam libero molestie egestas ridiculus, vulputate sociis cras quisque sagittis quis scelerisque curae orci hac lobortis, ut pellentesque vehicula non nunc viverra malesuada porta torquent. Dapibus vestibulum purus ullamcorper parturient fermentum arcu phasellus netus egestas vel viverra, penatibus interdum semper dis himenaeos diam senectus justo laoreet dignissim, facilisis est aptent ante fringilla maecenas libero rhoncus praesent quam.”",
						},
						{
							component: "ImageCard",
							file: {
								alt: "",
								publicId:
									"CegDEV/CEG-HeroPhoto_93f16f65-d9b4-446b-b59f-11a8d320907e",
								url: "",
							},
							name: "Lorem Ipsum",
							shortInfo: "Lorem Ipsum",
							degree: "Lorem ipsum dolor sit amet consectetur",
							title:
								"Rutrum praesent vel metus accumsan fames nam libero molestie egestas ridiculus.",
							description:
								"“ELorem ipsum dolor sit amet consectetur adipiscing elit hac vivamus, vel senectus ligula nostra dapibus ultrices accumsan est, metus a eleifend sociosqu quis urna id feugiat. Rutrum praesent vel metus accumsan fames nam libero molestie egestas ridiculus, vulputate sociis cras quisque sagittis quis scelerisque curae orci hac lobortis, ut pellentesque vehicula non nunc viverra malesuada porta torquent. Dapibus vestibulum purus ullamcorper parturient fermentum arcu phasellus netus egestas vel viverra, penatibus interdum semper dis himenaeos diam senectus justo laoreet dignissim, facilisis est aptent ante fringilla maecenas libero rhoncus praesent quam.”",
						},
					],
					layout: "L004",
				},
				{
					component: "StickyFooterCTA",
					phoneNumber: "+34 91 514 53 30",
					button: {
						component: "Button",
						buttonText: "Solicita información",
						appearance: "primary",
						size: "medium",
						url: {
							href: "",
							linkTo: null,
							newTab: false,
							noFollow: false,
						},
					},
				},
			],
		},
	},

	thumbnails: {},
};

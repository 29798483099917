import CLOUDINARY_BASE_UPLOAD_URL from "@constants/cloudinary/base-upload-url";
import { createSchema } from "@utils/index";

export default createSchema({
	schemaType: "module",
	displayName: "Cypher Collection",
	component: "CypherCollection",
	category: "collections",
	dataPacks: null,
	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "Cypher Card",
					type: "ComponentArray",
					maxItems: null,
					whiteList: ["CypherCard"],
					key: "elements",
					mandatory: true,
				},
			],
		},
		{
			title: "config",
			fields: [
				{
					title: "Style",
					key: "theme",
					type: "VisualUniqueSelection",
					mandatory: true,
					options: [
						{
							theme: "garrigues-theme",
							options: [
								{
									value: "default",
									img: `${CLOUDINARY_BASE_UPLOAD_URL}/themes/garrigues-theme/default.png`,
								},
								{
									value: "defaultAlt",
									img: `${CLOUDINARY_BASE_UPLOAD_URL}/themes/garrigues-theme/defaultAlt.png`,
								},
								{
									value: "accent",
									img: `${CLOUDINARY_BASE_UPLOAD_URL}/themes/garrigues-theme/accent.png`,
								},
								{
									value: "accentAlt",
									img: `${CLOUDINARY_BASE_UPLOAD_URL}/themes/garrigues-theme/accentAlt.png`,
								},
								{
									value: "accentLight",
									img: `${CLOUDINARY_BASE_UPLOAD_URL}/themes/garrigues-theme/accentLight.png`,
								},
							],
						},
						{
							theme: "eeg-theme",
							options: [
								{
									value: "default",
									img: `${CLOUDINARY_BASE_UPLOAD_URL}/themes/eeg-theme/default.png`,
								},
								{
									value: "defaultAlt",
									img: `${CLOUDINARY_BASE_UPLOAD_URL}/themes/eeg-theme/defaultAlt.png`,
								},
								{
									value: "accent",
									img: `${CLOUDINARY_BASE_UPLOAD_URL}/themes/eeg-theme/accent.png`,
								},
								{
									value: "accentAlt",
									img: `${CLOUDINARY_BASE_UPLOAD_URL}/themes/eeg-theme/accentAlt.png`,
								},
								{
									value: "accentLight",
									img: `${CLOUDINARY_BASE_UPLOAD_URL}/themes/eeg-theme/accentLight.png`,
								},
							],
						},
						{
							theme: "ipam-theme",
							options: [
								{
									value: "default",
									img: `${CLOUDINARY_BASE_UPLOAD_URL}/themes/ipam-theme/default.png`,
								},
								{
									value: "defaultAlt",
									img: `${CLOUDINARY_BASE_UPLOAD_URL}/themes/ipam-theme/defaultAlt.png`,
								},
								{
									value: "accent",
									img: `${CLOUDINARY_BASE_UPLOAD_URL}/themes/ipam-theme/accent.png`,
								},
								{
									value: "accentAlt",
									img: `${CLOUDINARY_BASE_UPLOAD_URL}/themes/ipam-theme/accentAlt.png`,
								},
								{
									value: "accentLight",
									img: `${CLOUDINARY_BASE_UPLOAD_URL}/themes/ipam-theme/accentLight.png`,
								},
							],
						},
						{
							theme: "iade-theme",
							options: [
								{
									value: "default",
									img: `${CLOUDINARY_BASE_UPLOAD_URL}/themes/iade-theme/default.png`,
								},
								{
									value: "defaultAlt",
									img: `${CLOUDINARY_BASE_UPLOAD_URL}/themes/iade-theme/defaultAlt.png`,
								},
								{
									value: "accent",
									img: `${CLOUDINARY_BASE_UPLOAD_URL}/themes/iade-theme/accent.png`,
								},
								{
									value: "accentAlt",
									img: `${CLOUDINARY_BASE_UPLOAD_URL}/themes/iade-theme/accentAlt.png`,
								},
								{
									value: "accentLight",
									img: `${CLOUDINARY_BASE_UPLOAD_URL}/themes/iade-theme/accentLight.png`,
								},
							],
						},
						{
							theme: "iade-es-theme",
							options: [
								{
									value: "default",
									img: `${CLOUDINARY_BASE_UPLOAD_URL}/themes/iade-es-theme/default.png`,
								},
								{
									value: "defaultAlt",
									img: `${CLOUDINARY_BASE_UPLOAD_URL}/themes/iade-es-theme/defaultAlt.png`,
								},
								{
									value: "accentAlt",
									img: `${CLOUDINARY_BASE_UPLOAD_URL}/themes/iade-es-theme/accentAlt.png`,
								},
							],
						},
					],
					columns: 8,
				},
			],
		},
	],

	default: {
		component: "CypherCollection",
		elements: [
			{
				component: "CypherCard",
				number: "0",
				subtitle: "Lorem ipsum",
				description: "Lorem ipsum dolor sit amet",
			},
			{
				component: "CypherCard",
				number: "0",
				subtitle: "Lorem ipsum",
				description: "Lorem ipsum dolor sit amet",
			},
			{
				component: "CypherCard",
				number: "0",
				subtitle: "Lorem ipsum",
				description: "Lorem ipsum dolor sit amet",
			},
		],
		theme: "default",
	},
	thumbnails: {
		"1x": `${CLOUDINARY_BASE_UPLOAD_URL}/thumbnails/CypherCollection_x2.png`,
		"2x": `${CLOUDINARY_BASE_UPLOAD_URL}/thumbnails/CypherCollection_x2.png`,
	},
});

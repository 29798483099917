import CLOUDINARY_BASE_UPLOAD_URL from "@constants/cloudinary/base-upload-url";
import { createSchema } from "@utils/index";

export default createSchema({
	schemaType: "module",
	displayName: "Staff Distributor",
	component: "StaffDistributor",
	category: "people",
	dataPacks: ["STAFF"],
	// sectionList: allSectionList,
	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "Title",
					type: "HeadingField",
					humanReadable: true,
					key: "title",
					advanced: true,
					hideable: true,
					default: { tag: "h2", content: "Title" },
					options: [
						{ value: "h1", label: "H1" },
						{ value: "h2", label: "H2" },
						{ value: "h3", label: "H3" },
						{ value: "h4", label: "H4" },
						{ value: "span", label: "span" },
					],
				},
				{
					title: "Description",
					type: "TextField",
					key: "description",
					mandatory: false,
					humanReadable: true,
				},
				{
					title: "Staff",
					type: "ReferenceField",
					sources: [{ structuredData: "STAFF" }],
					key: "data",
				},
			],
		},
	],

	default: {
		component: "StaffDistributor",
		title: { content: "Title", tag: "h2" },
		description: "Lorem Ipsum",
		data: {
			mode: "auto",
			sources: [{ structuredData: "STAFF" }],
			order: "alpha",
			quantity: 15,
		},
		layout: "L001",
		hasDistributorData: true,
	},
	thumbnails: {
		"1x": `${CLOUDINARY_BASE_UPLOAD_URL}/thumbnails/StaffDistributor_x2.png`,
		"2x": `${CLOUDINARY_BASE_UPLOAD_URL}/thumbnails/StaffDistributor_x2.png`,
	},
});

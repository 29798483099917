import cloudinaryCloudName from "@constants/cloudinary/cloud-name";
// eslint-disable-next-line import/order
import cloudinaryDefaults from "@constants/cloudinary/defaults";

import "@css/.themes/web-fonts.css";

// Reset amd normalize CSS
import "@css/custom/normalize.css";
import "@css/custom/reset.css";

// Grisso CSS (reduced)
import "@css/.grisso/grisso.min.css";

// Instance THEME
import "@css/.themes/global-theme.css";
import "@css/.themes/theme.css";
import "@css/.themes/text-styles.css";
// Custom CSS
import "@css/custom/global.css";
// import "@css/custom/text-styles.css";
import "@css/custom/forms.css";
import "@css/custom/loading.css";

import {
	PageContext,
	PageProvider,
	SiteContext,
	SiteProvider as CoreSiteProvider,
} from "@griddo/core";
import {
	createGlobalTheme,
	createSiteTheme,
	createSubThemes,
	GlobalCSS,
} from "@themes/utils";
import translations from "@translations/index";
import * as React from "react";
import { ThemeContext, ThemeProvider } from "styled-components";
// Por ahora es un objeto vacío hasta que se implemente Griddo Dam en el proyecto
const griddoDamDefaults = {};

// eslint-disable-next-line @typescript-eslint/no-var-requires,import/order
const { instance } = require("../../../instances/current.json");

const AnimationContext = React.createContext(null);

const AnimationProvider = ({ children, ...rest }) => (
	<AnimationContext.Provider value={{ ...rest }}>
		{children}
	</AnimationContext.Provider>
);

const _languages = {
	en: "ingles",
	pt_PT: "portugues",
	es: "español",
};

const SiteProvider = (props) => {
	const globalTheme = createGlobalTheme();
	// Breakpoints Alias.
	// Ahora es posible también acceder a los breakpoints de las props mediante un
	// objeto:
	// <Box width={{ s: "100px", xl: "300px" }}
	globalTheme.mediaquery.mediaqueries.forEach((mq) => {
		globalTheme.breakpoints[mq.label] = mq.minWidth;
	});

	const { theme, children, subtheme } = props;

	const currentLang =
		props?.siteLangs?.filter(
			({ id }) =>
				(children[1]?.props?.languageId || children?.props?.languageId) === id
		)[0] || {};

	currentLang.normalizedName = _languages[currentLang.locale] || "undefined";

	const siteTheme = {
		...createSiteTheme({ theme: theme }),
		subthemes: {
			...createSubThemes({ theme: theme }),
		},
	};

	// If siteTheme has mediaqueries...
	if (siteTheme.mediaquery) {
		siteTheme.mediaquery.mediaqueries.forEach((mq) => {
			siteTheme.breakpoints[mq.label] = mq.minWidth;
		});
	}

	return (
		<CoreSiteProvider
			griddoDamDefaults={griddoDamDefaults}
			translations={translations}
			instanceName={instance}
			{...props}
			instance={instance}
			currentLang={currentLang || []}
			cloudinaryDefaults={cloudinaryDefaults}
			cloudinaryCloudName={cloudinaryCloudName}
		>
			<ThemeProvider theme={{ ...globalTheme, ...siteTheme }}>
				<GlobalCSS />
				<div
					data-site-theme={theme}
					data-theme={theme}
					data-subtheme={subtheme || "default"}
					style={{ height: "100%" }}
				>
					<React.Suspense fallback={null}>{children}</React.Suspense>
				</div>
			</ThemeProvider>
		</CoreSiteProvider>
	);
};

export {
	PageContext,
	PageProvider,
	SiteContext,
	SiteProvider,
	ThemeProvider,
	ThemeContext,
	AnimationContext,
	AnimationProvider,
};

import CLOUDINARY_BASE_UPLOAD_URL from "@constants/cloudinary/base-upload-url";

export default {
	schemaType: "template",
	displayName: "Quizz",
	component: "Quizz",
	dataPacks: ["QUIZZ"],
	type: { label: "Quizz", value: "quizz", mode: "detail" },
	content: [
		// QUESTIONS
		{
			title: "Intro",
			type: "RichText",
			key: "intro",
			mandatory: true,
		},
		{
			title: "Start Button Text",
			type: "TextField",
			key: "buttonText",
			mandatory: true,
		},
		{
			title: "Background",
			type: "ImageField",
			key: "background",
			hideable: true,
		},
		{
			title: "Mobile Background",
			type: "ImageField",
			key: "mobileBackground",
			hideable: true,
		},
		{
			title: "Mobile Questions Image",
			type: "ImageField",
			key: "mobileImage",
			hideable: true,
		},
		{
			type: "ArrayFieldGroup",
			title: "Quizz Questions",
			key: "questions",
			name: "Question",
			fields: [
				{
					title: "Text",
					type: "TextField",
					key: "text",
				},
				{
					title: "Image",
					type: "ImageField",
					key: "image",
				},
				{
					type: "ArrayFieldGroup",
					title: "Question Answers",
					key: "answers",
					name: "Answer",
					fields: [
						{
							title: "Text",
							type: "TextField",
							key: "text",
						},
						{
							title: "Studies linked to this answer",
							type: "AsyncCheckGroup",
							source: "STUDY",
							key: "studies",
						},
					],
				},
			],
		},
		{
			title: "Salesforce ID",
			type: "TextField",
			key: "salesforceId",
		},
		{
			title: "Campaign ID",
			type: "TextField",
			key: "campaignId",
		},

		// FORM
		{
			title: "Form Title",
			type: "TextField",
			key: "formTitle",
		},
		{
			title: "Form Intro",
			type: "RichText",
			key: "formIntro",
		},

		// RESULTS
		{
			title: "Result Title",
			type: "TextField",
			key: "resultTitle",
		},
		{
			title: "Result Intro",
			type: "RichText",
			key: "resultIntro",
		},
		{
			title: "Disable results list",
			type: "RadioGroup",
			key: "disableResults",
			options: [
				{
					title: "No",
					name: "no",
					value: "no",
				},
				{
					title: "Yes",
					name: "yes",
					value: "yes",
				},
			],
		},

		{
			title: "Style",
			key: "theme",
			type: "VisualUniqueSelection",
			mandatory: true,
			options: [
				{
					theme: "ipam-theme",
					options: [
						{
							value: "defaultAlt",
							img: `${CLOUDINARY_BASE_UPLOAD_URL}/themes/ipam-theme/defaultAlt.png`,
						},
						{
							value: "accentLight",
							img: `${CLOUDINARY_BASE_UPLOAD_URL}/themes/ipam-theme/accentLight.png`,
						},
						{
							value: "accentAlt",
							img: `${CLOUDINARY_BASE_UPLOAD_URL}/themes/ipam-theme/accentAlt.png`,
						},
					],
				},
				{
					theme: "iade-theme",
					options: [
						{
							value: "defaultAlt",
							img: `${CLOUDINARY_BASE_UPLOAD_URL}/themes/iade-theme/defaultAlt.png`,
						},
						{
							value: "accentLight",
							img: `${CLOUDINARY_BASE_UPLOAD_URL}/themes/iade-theme/accentLight.png`,
						},
						{
							value: "accentAlt",
							img: `${CLOUDINARY_BASE_UPLOAD_URL}/themes/iade-theme/accentAlt.png`,
						},
					],
				},
			],
			columns: 8,
		},
	],

	default: {
		type: "template",
		templateType: "Quizz",
		intro: "Lorem ipsum",
		buttonText: "Let's get started",
		disableResults: "no",
		theme: "defaultAlt",
	},

	thumbnails: {},
};

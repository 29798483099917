import CLOUDINARY_BASE_UPLOAD_URL from "@constants/cloudinary/base-upload-url";
import { createSchema } from "@utils/index";

export default createSchema({
	schemaType: "module",
	displayName: "Featured Block",
	component: "FeaturedBlock",
	category: "featured",
	dataPacks: null,
	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "Title",
					type: "HeadingField",
					humanReadable: true,
					key: "title",
					advanced: true,
					mandatory: true,
					default: { tag: "h2", content: "Title" },
					options: [
						{ value: "h1", label: "H1" },
						{ value: "h2", label: "H2" },
						{ value: "h3", label: "H3" },
						{ value: "h4", label: "H4" },
						{ value: "span", label: "span" },
					],
				},
			],
		},
		{
			title: "config",
			fields: [
				{
					title: "Style",
					key: "theme",
					type: "VisualUniqueSelection",
					mandatory: true,
					options: [
						{
							theme: "garrigues-theme",
							options: [
								{
									value: "default",
									img: `${CLOUDINARY_BASE_UPLOAD_URL}/themes/garrigues-theme/default.png`,
								},
								{
									value: "defaultAlt",
									img: `${CLOUDINARY_BASE_UPLOAD_URL}/themes/garrigues-theme/defaultAlt.png`,
								},
								{
									value: "accent",
									img: `${CLOUDINARY_BASE_UPLOAD_URL}/themes/garrigues-theme/accent.png`,
								},
								{
									value: "accentAlt",
									img: `${CLOUDINARY_BASE_UPLOAD_URL}/themes/garrigues-theme/accentAlt.png`,
								},
								{
									value: "accentLight",
									img: `${CLOUDINARY_BASE_UPLOAD_URL}/themes/garrigues-theme/accentLight.png`,
								},
							],
						},
						{
							theme: "eeg-theme",
							options: [
								{
									value: "default",
									img: `${CLOUDINARY_BASE_UPLOAD_URL}/themes/eeg-theme/default.png`,
								},
								{
									value: "defaultAlt",
									img: `${CLOUDINARY_BASE_UPLOAD_URL}/themes/eeg-theme/defaultAlt.png`,
								},
								{
									value: "accent",
									img: `${CLOUDINARY_BASE_UPLOAD_URL}/themes/eeg-theme/accent.png`,
								},
								{
									value: "accentAlt",
									img: `${CLOUDINARY_BASE_UPLOAD_URL}/themes/eeg-theme/accentAlt.png`,
								},
								{
									value: "accentLight",
									img: `${CLOUDINARY_BASE_UPLOAD_URL}/themes/eeg-theme/accentLight.png`,
								},
							],
						},
						{
							theme: "ipam-theme",
							options: [
								{
									value: "default",
									img: `${CLOUDINARY_BASE_UPLOAD_URL}/themes/ipam-theme/default.png`,
								},
								{
									value: "defaultAlt",
									img: `${CLOUDINARY_BASE_UPLOAD_URL}/themes/ipam-theme/defaultAlt.png`,
								},
								{
									value: "accent",
									img: `${CLOUDINARY_BASE_UPLOAD_URL}/themes/ipam-theme/accent.png`,
								},
								{
									value: "accentAlt",
									img: `${CLOUDINARY_BASE_UPLOAD_URL}/themes/ipam-theme/accentAlt.png`,
								},
								{
									value: "accentLight",
									img: `${CLOUDINARY_BASE_UPLOAD_URL}/themes/ipam-theme/accentLight.png`,
								},
							],
						},
						{
							theme: "iade-theme",
							options: [
								{
									value: "default",
									img: `${CLOUDINARY_BASE_UPLOAD_URL}/themes/iade-theme/default.png`,
								},
								{
									value: "defaultAlt",
									img: `${CLOUDINARY_BASE_UPLOAD_URL}/themes/iade-theme/defaultAlt.png`,
								},
								{
									value: "accent",
									img: `${CLOUDINARY_BASE_UPLOAD_URL}/themes/iade-theme/accent.png`,
								},
								{
									value: "accentAlt",
									img: `${CLOUDINARY_BASE_UPLOAD_URL}/themes/iade-theme/accentAlt.png`,
								},
								{
									value: "accentLight",
									img: `${CLOUDINARY_BASE_UPLOAD_URL}/themes/iade-theme/accentLight.png`,
								},
							],
						},
						{
							theme: "iade-es-theme",
							options: [
								{
									value: "default",
									img: `${CLOUDINARY_BASE_UPLOAD_URL}/themes/iade-es-theme/default.png`,
								},
								{
									value: "defaultAlt",
									img: `${CLOUDINARY_BASE_UPLOAD_URL}/themes/iade-es-theme/defaultAlt.png`,
								},
								{
									value: "accent",
									img: `${CLOUDINARY_BASE_UPLOAD_URL}/themes/iade-es-theme/accent.png`,
								},
								{
									value: "accentAlt",
									img: `${CLOUDINARY_BASE_UPLOAD_URL}/themes/iade-es-theme/accentAlt.png`,
								},
							],
						},
					],
					columns: 8,
				},
			],
		},
	],

	default: {
		component: "FeaturedBlock",
		title: {
			content: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit",
			tag: "h2",
		},
		theme: "default",
	},
	thumbnails: {
		"1x": `${CLOUDINARY_BASE_UPLOAD_URL}/thumbnails/FeaturedBlock_x2.png`,
		"2x": `${CLOUDINARY_BASE_UPLOAD_URL}/thumbnails/FeaturedBlock_x2.png`,
	},
});

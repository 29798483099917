import { useSite } from "./index";

export const useCheckInstance = () => {
	const { instance } = useSite();

	const isIADE = instance.includes("IADE");
	const isEEG = instance.includes("EEG");
	const isIPAM = instance.includes("IPAM");
	const isCEG = instance.includes("CEG");
	const isIADEES = instance.includes("iadeES");

	const isPortugal = isIADE || isIPAM;

	// NOTE: Variable para saber si la instancia ya tiene las nuevas primitivas
	const hasNewPrimitives = isIADEES;

	return {
		isIADE,
		isEEG,
		isIPAM,
		isCEG,
		isIADEES,
		hasNewPrimitives,
		isPortugal,
	};
};

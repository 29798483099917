// Mandatory modules
import AccordionElement from "./AccordionElement";
import AgendaElement from "./AgendaElement";
import ArtDirectionImageHorizontalVertical from "./ArtDirectionImageHorizontalVertical";
import AwardsCard from "./AwardsCard";
import BasicCard from "./BasicCard";
import Button from "./Button";
import ButtonToTop from "./ButtonToTop";
import CypherCard from "./CypherCard";
import DateButton from "./DateButton";
import DescriptionCard from "./DescriptionCard";
import EventCard from "./EventCard";
import FAQElement from "./FAQElement";
import File from "./File";
import FormTypePrivacyPolicy from "./FormTypePrivacyPolicy";
import FormTypeStudyDistributor from "./FormTypeStudyDistributor";
import GroupingElement from "./GroupingElement";
import HorizontalTabElement from "./HorizontalTabElement";
import IconCard from "./IconCard";
import Image from "./Image";
import ImageCard from "./ImageCard";
import LandingEventCard from "./LandingEventCard";
import Link from "./Link";
import LinkableImage from "./LinkableImage";
import LinkCard from "./LinkCard";
import NewsCard from "./NewsCard";
import PhoneNumberElement from "./PhoneNumberElement";
import PhoneNumbersList from "./PhoneNumbersList";
import QuickFacts from "./QuickFacts";
import QuickFactsElement from "./QuickFactsElement";
import RelatedProgramsElement from "./RelatedProgramsElement";
import SmallCard from "./SmallCard";
import SolidCard from "./SolidCard";
import StaffCardLanding from "./StaffCardLanding";
import StudyAgregatorCard from "./StudyAgregatorCard";
import StudyAgregatorElement from "./StudyAgregatorElement";
import StudyCard from "./StudyCard";
import TableRow from "./TableRow";
import TeacherCard from "./TeacherCard";
import TestimonialsCard from "./TestimonialsCard";
import TestimonialsCardLanding from "./TestimonialsCardLanding";
import Video from "./Video";
import VideoTitle from "./VideoTitle";

export default {
	AccordionElement,
	AgendaElement,
	ArtDirectionImageHorizontalVertical,
	AwardsCard,
	BasicCard,
	Button,
	ButtonToTop,
	CypherCard,
	DateButton,
	DescriptionCard,
	EventCard,
	FAQElement,
	File,
	FormTypePrivacyPolicy,
	FormTypeStudyDistributor,
	GroupingElement,
	HorizontalTabElement,
	IconCard,
	Image,
	ImageCard,
	LandingEventCard,
	Link,
	LinkableImage,
	LinkCard,
	NewsCard,
	PhoneNumberElement,
	PhoneNumbersList,
	QuickFacts,
	QuickFactsElement,
	RelatedProgramsElement,
	SmallCard,
	SolidCard,
	StaffCardLanding,
	StudyAgregatorCard,
	StudyAgregatorElement,
	StudyCard,
	TableRow,
	TeacherCard,
	TestimonialsCard,
	TestimonialsCardLanding,
	Video,
	VideoTitle,
};

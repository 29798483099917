import layoutColumnsThumb from "@axthumbs/layout/studydistributor-columns.png";
import layoutDefaultThumb from "@axthumbs/layout/studydistributor-default.png";
import CLOUDINARY_BASE_UPLOAD_URL from "@constants/cloudinary/base-upload-url";
import { instance } from "@instance-config";
import createThemeStyles from "@schemas/commons/getStyles";
import { createSchema } from "@utils/";

export default createSchema({
	schemaType: "module",
	displayName: "Study Distributor",
	component: "StudyDistributor",
	category: "collection",
	dataPacks: ["STUDY"],
	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "Title",
					type: "HeadingField",
					humanReadable: true,
					key: "title",
					advanced: true,
					hideable: true,
					default: { tag: "h2", content: "Title" },
					options: [
						{ value: "h1", label: "H1" },
						{ value: "h2", label: "H2" },
						{ value: "h3", label: "H3" },
						{ value: "h4", label: "H4" },
						{ value: "span", label: "span" },
					],
				},
				{
					title: "Description",
					type: "TextField",
					humanReadable: true,
					key: "description",
					mandatory: false,
				},
				{
					title: "Study",
					type: "ReferenceField",
					sources: [{ structuredData: "STUDY" }],
					key: "data",
				},
			],
		},
		{
			title: "config",
			fields: [
				{
					title: "Layout",
					key: "layout",
					type: "VisualUniqueSelection",
					options: [
						{
							value: "default",
							img: layoutDefaultThumb,
						},
						{
							value: "columns",
							img: layoutColumnsThumb,
						},
					],
					columns: 2,
				},

				{
					title: "Style",
					key: "theme",
					type: "VisualUniqueSelection",
					mandatory: true,
					options: [
						{
							...createThemeStyles("garrigues-theme", [
								'default", "defaultAlt',
							]),
						},
						{ ...createThemeStyles("ipam-theme", ["defaultAlt"]) },
						{ ...createThemeStyles("iade-theme", ["default", "defaultAlt"]) },
						{ ...createThemeStyles("iade-es-theme", ["default"]) },
					],
					columns: 8,
				},
			],
		},
	],

	default: {
		component: "StudyDistributor",
		title: { content: "Title", tag: "h2" },
		description: "Lorem Ipsum",
		data: {
			mode: "auto",
			sources: [{ structuredData: "STUDY" }],
			order: "alpha",
			quantity: 15,
			allLanguages: true,
		},
		hasDistributorData: true,
		theme: instance.includes("IPAM") ? "defaultAlt" : "default",
		layout: "default",
	},
	thumbnails: {
		"1x": `${CLOUDINARY_BASE_UPLOAD_URL}/thumbnails/StudyDistributor_x2.png`,
		"2x": `${CLOUDINARY_BASE_UPLOAD_URL}/thumbnails/StudyDistributor_x2.png`,
	},
});

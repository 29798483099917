import { instance } from "@instance-config";
import mountStudySchema from "@schemas/commons/Study";
import { basicModuleList, additionalModuleList } from "@schemas/modules";
import { createSchema } from "@utils/index";

const isIADEES = instance.includes("iadeES");
const isIADE = instance.includes("IADE");
const isIPAM = instance.includes("IPAM");

const element1 = {
	component: "GroupingElement",
	title: "Section 1",
	componentModules: [
		{
			component: "Overview",
			title: { content: "Title", tag: "h1" },
			description:
				"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu.",
			conditionalForm: true,
			email: "example@email.com",
			quickFacts: {
				component: "QuickFacts",
				elements: [
					{
						component: "QuickFactsElement",
						title: "Lorem Ipsum",
						description: "Lorem ipsum dolor ",
					},
					{
						component: "QuickFactsElement",
						title: "Lorem Ipsum",
						description: "Lorem ipsum dolor ",
					},
					{
						component: "QuickFactsElement",
						title: "Lorem Ipsum",
						description: "Lorem ipsum dolor ",
					},
					{
						component: "QuickFactsElement",
						title: "Lorem Ipsum",
						description: "Lorem ipsum dolor ",
					},
				],
			},
			...((isIPAM || isIADE) && {
				downloadButton: {
					component: "Button",
					buttonText: "Download Brochura",
					appearance: "secondary",
					size: "big",
					url: {
						href: "",
						linkTo: null,
						newTab: false,
						noFollow: false,
					},
				},
				file: "",
			}),
			highlightedDownload: {
				component: "HighlightedDownload",
				title: null,
				subtitle: "Lorem Ipsum",
				alt: true,
				button: {
					component: "Button",
					buttonText: "Descargar",
					appearance: "secondary",
					size: "medium",
					url: {
						href: "",
						linkTo: null,
						newTab: false,
						noFollow: false,
					},
				},
			},
			theme: isIPAM ? "accentAlt" : isIADE ? "accent" : "defaultAlt",
		},
		{
			component: "FeaturedAudiovisual",
			videoBig: {
				component: "Video",
				thumbnail: {
					component: "Image",
					file: {
						publicId:
							"CegDEV/CEG-HeroPhoto_93f16f65-d9b4-446b-b59f-11a8d320907e",
					},
					veil: 40,
				},
				url: "",
			},
			videoSmall: {
				component: "Video",
				thumbnail: {
					component: "Image",
					file: {
						publicId:
							"CegDEV/CEG-HeroPhoto_93f16f65-d9b4-446b-b59f-11a8d320907e",
					},
					veil: 40,
				},
				url: "",
			},
			descriptionCard: {
				component: "DescriptionCard",
				title: {
					content: "Description Card Title",
					tag: "h4",
				},
				description: "Lorem ipsum dolor sit amet",
				button: {
					component: "Button",
					buttonText: "Ver detalle",
					appearance: "link",
					size: "small",
					url: {
						href: "",
						linkTo: null,
						newTab: false,
						noFollow: false,
					},
				},
			},
		},
		{
			component: "CardCollection",
			title: { content: "Title", tag: "h2" },
			kind: "BasicCard",
			elements: [
				{
					component: "BasicCard",
					title: "Lorem ipsum",
					description:
						"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu. ",
					file: {
						alt: "",
						publicId:
							"CegDEV/CEG-HeroPhoto_93f16f65-d9b4-446b-b59f-11a8d320907e",
						url: "",
					},
					veil: 40,
					button: {
						component: "Button",
					},
				},
				{
					component: "BasicCard",
					title: "Lorem ipsum",
					description:
						"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu. ",
					file: {
						alt: "",
						publicId:
							"CegDEV/CEG-HeroPhoto_93f16f65-d9b4-446b-b59f-11a8d320907e",
						url: "",
					},
					veil: 40,
					button: {
						component: "Button",
					},
				},
			],
			button: {
				component: "Button",
				buttonText: "Link",
				appearance: "link",
				size: "small",
				url: {
					href: "",
					linkTo: null,
					newTab: false,
					noFollow: false,
				},
			},
			layout: "L004",
		},
		{
			component: "SponsorsLogos",
			title: { content: "Title", tag: "h3" },
			subtitle: "Lorem Ipsum",
			elements: [
				{
					component: "LinkableImage",
					file: {
						alt: "",
						publicId:
							"CegDEV/CEG-HeroPhoto_93f16f65-d9b4-446b-b59f-11a8d320907e",
						url: "",
					},
				},
				{
					component: "LinkableImage",
					file: {
						alt: "",
						publicId:
							"CegDEV/CEG-HeroPhoto_93f16f65-d9b4-446b-b59f-11a8d320907e",
						url: "",
					},
				},
				{
					component: "LinkableImage",
					file: {
						alt: "",
						publicId:
							"CegDEV/CEG-HeroPhoto_93f16f65-d9b4-446b-b59f-11a8d320907e",
						url: "",
					},
				},
				{
					component: "LinkableImage",
					file: {
						alt: "",
						publicId:
							"CegDEV/CEG-HeroPhoto_93f16f65-d9b4-446b-b59f-11a8d320907e",
						url: "",
					},
				},
			],
			componentModules: {
				component: "SponsorsLogosLightbox",
				title: "Lorem Ipsum",
				text: "Lorem Ipsum",
				elements: [
					{
						component: "LinkableImage",
						file: {
							alt: "",
							publicId:
								"CegDEV/CEG-HeroPhoto_93f16f65-d9b4-446b-b59f-11a8d320907e",
							url: "",
						},
					},
					{
						component: "LinkableImage",
						file: {
							alt: "",
							publicId:
								"CegDEV/CEG-HeroPhoto_93f16f65-d9b4-446b-b59f-11a8d320907e",
							url: "",
						},
					},
					{
						component: "LinkableImage",
						file: {
							alt: "",
							publicId:
								"CegDEV/CEG-HeroPhoto_93f16f65-d9b4-446b-b59f-11a8d320907e",
							url: "",
						},
					},
					{
						component: "LinkableImage",
						file: {
							alt: "",
							publicId:
								"CegDEV/CEG-HeroPhoto_93f16f65-d9b4-446b-b59f-11a8d320907e",
							url: "",
						},
					},
				],
				button: {
					component: "Button",
					buttonText: "Ver detalle",
					appearance: "link",
					size: "small",
					url: {
						href: "",
						linkTo: null,
						newTab: false,
						noFollow: false,
					},
				},
			},
			button: {
				component: "Button",
				buttonText: "Ver detalle",
				appearance: "link",
				size: "small",
				url: {
					href: "",
					linkTo: null,
					newTab: false,
					noFollow: false,
				},
			},
		},
		{
			component: "Testimonials",
			title: { content: "Title", tag: "h2" },
			data: {
				mode: "auto",
				sources: [{ structuredData: "TESTIMONIALS" }],
				order: "alpha",
				quantity: 15,
			},
			hasDistributorData: true,
			button: {
				component: "Button",
				buttonText: "Link",
				appearance: "link",
				size: "small",
				url: {
					href: "",
					linkTo: null,
					newTab: false,
					noFollow: false,
				},
			},
			layout: "L001",
		},
		{
			component: "HighLightedCTA",
			icon: {
				alt: "",
				publicId: "CegDEV/CEG-HeroPhoto_93f16f65-d9b4-446b-b59f-11a8d320907e",
			},
			title: {
				content: "Title",
				tag: "h3",
			},
			subtitle: "Lorem ipsum",
			text: "Lorem ipsum",
			button: {
				component: "Button",
				buttonText: "Lorem Ipsum",
				appearance: "secondary",
				size: "big",
				url: {
					href: "",
					linkTo: null,
					newTab: false,
					noFollow: false,
				},
			},
			theme: "accentLight",
		},
	],
};
const element2 = {
	component: "GroupingElement",
	title: "Section 2",
	componentModules: [
		{
			component: "HighLightedCTA",
			icon: {
				alt: "",
				publicId: "CegDEV/CEG-HeroPhoto_93f16f65-d9b4-446b-b59f-11a8d320907e",
			},
			title: {
				content: "Title",
				tag: "h3",
			},
			subtitle: "Lorem ipsum",
			text: "Lorem ipsum",
			button: {
				component: "Button",
				buttonText: "Lorem Ipsum",
				appearance: "secondary",
				size: "big",
				url: {
					href: "",
					linkTo: null,
					newTab: false,
					noFollow: false,
				},
			},
			theme: "accentLight",
		},
		{
			component: "Accordion",
			title: { content: "Title", tag: "h4" },
			theme: "default",
			elements: [
				{
					component: "AccordionElement",
					title: { content: "Title", tag: "h3" },
					componentModules: [
						{
							component: "CardCollection",
							title: { content: "Title", tag: "h2" },
							subtitle: "Lorem Ipsum",
							kind: "BasicCard",
							elements: [
								{
									component: "BasicCard",
									title: "Lorem ipsum",
									description:
										"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu. ",
									file: {
										alt: "",
										publicId:
											"CegDEV/CEG-HeroPhoto_93f16f65-d9b4-446b-b59f-11a8d320907e",
										url: "",
									},
									veil: 0,
									button: {
										component: "Button",
										buttonText: "Link",
										appearance: "link",
										size: "medium",
										url: {
											href: "",
											linkTo: null,
											newTab: false,
											noFollow: false,
										},
									},
								},
							],
							button: {
								component: "Button",
								buttonText: "Link",
								appearance: "link",
								size: "small",
								url: {
									href: "",
									linkTo: null,
									newTab: false,
									noFollow: false,
								},
							},
							layout: "L001",
						},
					],
				},
			],
		},
		{
			component: "BasicContent",
			title: { content: "Basic content title text", tag: "h2" },
			subtitle: "Subtitle text",
			detail: "Detail text",
			image: {
				component: "Image",
				alt: "Alt",
				file: {
					publicId: "CegDEV/CEG-HeroPhoto_93f16f65-d9b4-446b-b59f-11a8d320907e",
				},
			},
			imageText: "Image detail",
			button: {
				component: "Button",
				buttonText: "Link",
				appearance: "link",
				size: "medium",
				url: {
					href: "",
					linkTo: null,
					newTab: false,
					noFollow: false,
				},
			},
			layout: "L001",
		},
		{
			component: "DownloadBox",
			title: { content: "Title", tag: "h3" },
			subtitle:
				"Descarga los documentos pdf con la información en detalle de contratación en cada una de las promociones desde 2005.",
			downloadBoxText: "Lorem Ipsum",
			button: {
				component: "Button",
				buttonText: "Descargar",
				appearance: "secondary",
				size: "small",
				url: {
					href: "",
					linkTo: null,
					newTab: true,
					noFollow: false,
				},
			},
			elements: [],
			layout: "L001",
			theme: isIADEES ? "defaultAlt" : "default",
		},
		{
			component: "CardCollection",
			title: { content: "Title", tag: "h2" },
			kind: "BasicCard",
			elements: [
				{
					component: "BasicCard",
					title: "Lorem ipsum",
					description:
						"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu. ",
					file: {
						alt: "",
						publicId:
							"CegDEV/CEG-HeroPhoto_93f16f65-d9b4-446b-b59f-11a8d320907e",
						url: "",
					},
					veil: 40,
					button: {
						component: "Button",
					},
				},
				{
					component: "BasicCard",
					title: "Lorem ipsum",
					description:
						"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu. ",
					file: {
						alt: "",
						publicId:
							"CegDEV/CEG-HeroPhoto_93f16f65-d9b4-446b-b59f-11a8d320907e",
						url: "",
					},
					veil: 40,
					button: {
						component: "Button",
					},
				},
			],
			button: {
				component: "Button",
				buttonText: "Link",
				appearance: "link",
				size: "small",
				url: {
					href: "",
					linkTo: null,
					newTab: false,
					noFollow: false,
				},
			},
			layout: "L004",
		},
		{
			component: "Accordion",
			title: { content: "Title", tag: "h4" },
			theme: "default",
			elements: [
				{
					component: "AccordionElement",
					title: { content: "Title", tag: "h3" },
					componentModules: [
						{
							component: "CardCollection",
							title: { content: "Title", tag: "h2" },
							subtitle: "Lorem Ipsum",
							kind: "BasicCard",
							elements: [
								{
									component: "BasicCard",
									title: "Lorem ipsum",
									description:
										"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu. ",
									file: {
										alt: "",
										publicId:
											"CegDEV/CEG-HeroPhoto_93f16f65-d9b4-446b-b59f-11a8d320907e",
										url: "",
									},
									veil: 0,
									button: {
										component: "Button",
										buttonText: "Link",
										appearance: "link",
										size: "medium",
										url: {
											href: "",
											linkTo: null,
											newTab: false,
											noFollow: false,
										},
									},
								},
							],
							button: {
								component: "Button",
								buttonText: "Link",
								appearance: "link",
								size: "small",
								url: {
									href: "",
									linkTo: null,
									newTab: false,
									noFollow: false,
								},
							},
							layout: "L001",
						},
					],
				},
			],
		},
	],
};
const element3 = {
	component: "GroupingElement",
	title: "Section 3",
	componentModules: [
		{
			component: "HighLightedCTA",
			icon: {
				alt: "",
				publicId: "CegDEV/CEG-HeroPhoto_93f16f65-d9b4-446b-b59f-11a8d320907e",
			},
			title: {
				content: "Title",
				tag: "h3",
			},
			subtitle: "Lorem ipsum",
			text: "Lorem ipsum",
			button: {
				component: "Button",
				buttonText: "Lorem Ipsum",
				appearance: "secondary",
				size: "big",
				url: {
					href: "",
					linkTo: null,
					newTab: false,
					noFollow: false,
				},
			},
			theme: "accentLight",
		},
		{
			component: "CardCollection",
			title: { content: "Title", tag: "h2" },
			kind: "BasicCard",
			elements: [
				{
					component: "BasicCard",
					title: "Lorem ipsum",
					description:
						"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu. ",
					file: {
						alt: "",
						publicId:
							"CegDEV/CEG-HeroPhoto_93f16f65-d9b4-446b-b59f-11a8d320907e",
						url: "",
					},
					veil: 40,
					button: {
						component: "Button",
					},
				},
				{
					component: "BasicCard",
					title: "Lorem ipsum",
					description:
						"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu. ",
					file: {
						alt: "",
						publicId:
							"CegDEV/CEG-HeroPhoto_93f16f65-d9b4-446b-b59f-11a8d320907e",
						url: "",
					},
					veil: 40,
					button: {
						component: "Button",
					},
				},
			],
			button: {
				component: "Button",
				buttonText: "Link",
				appearance: "link",
				size: "small",
				url: {
					href: "",
					linkTo: null,
					newTab: false,
					noFollow: false,
				},
			},
			layout: "L004",
		},
		{
			component: "HighLightedCTA",
			icon: {
				alt: "",
				publicId: "CegDEV/CEG-HeroPhoto_93f16f65-d9b4-446b-b59f-11a8d320907e",
			},
			title: {
				content: "Title",
				tag: "h3",
			},
			subtitle: "Lorem ipsum",
			text: "Lorem ipsum",
			button: {
				component: "Button",
				buttonText: "Lorem Ipsum",
				appearance: "secondary",
				size: "big",
				url: {
					href: "",
					linkTo: null,
					newTab: false,
					noFollow: false,
				},
			},
			theme: "accentLight",
		},
		{
			component: "EventDistributor",
			title: { content: "Title", tag: "h2" },
			kind: "EventCard",
			data: {
				mode: "auto",
				sources: [{ structuredData: "EVENTS" }],
				order: "alpha",
				quantity: 15,
			},
			hasDistributorData: true,
			button: {
				component: "Button",
				buttonText: "Ver todos los eventos",
				appearance: "link",
				size: "small",
				url: {
					href: "",
					linkTo: null,
					newTab: false,
					noFollow: false,
				},
			},
			layout: "L001",
		},
		{
			component: "AddressCollection",
			title: { content: "Title", tag: "h3" },
			subtitle: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
			address: "Lorem ipsum dolor sit amet",
			email: "email@email.com",
			phone: "+34 (0) 00 00 00 00 00",
			button: {
				component: "Button",
				buttonText: "Cómo Llegar",
				appearance: "secondary",
				size: "big",
				url: {
					href: "",
					linkTo: null,
					newTab: false,
					noFollow: false,
				},
			},
			backgroundImage: {
				component: "Image",
				alt: "Alt",
				file: {
					publicId: "CegDEV/CEG-HeroPhoto_93f16f65-d9b4-446b-b59f-11a8d320907e",
				},
				veil: 0,
			},
		},
	],
};

if (isIADEES) {
	element1.componentModules.push({
		component: "StaffDistributor",
		title: { content: "Title", tag: "h2" },
		description: "Lorem Ipsum",
		data: {
			mode: "auto",
			sources: [{ structuredData: "STAFF" }],
			order: "alpha",
			quantity: 15,
		},
		hasDistributorData: true,
		layout: "L001",
	});
}

const groupingElements = [{ ...element1 }, { ...element2 }, { ...element3 }];

const schemaBase = {
	schemaType: "template",
	displayName: "Study Detail",
	component: "StudyDetail",
	dataPacks: ["STUDY"],
	type: { label: "Study", value: "study", mode: "detail" },
	content: [
		{
			title: "Hero Section",
			type: "ComponentArray",
			maxItems: 1,
			whiteList: ["HeroHome", "HeroStudy", "InnerTitle"],
			key: "heroSection",
		},
	],
	default: {
		type: "template",
		templateType: "StudyDetail",
		heroSection: {
			component: "Section",
			name: "Hero Section",
			modules: [
				{
					component: "HeroStudy",
					subtitle: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
					hightlightText: "Lorem ipsum dolor sit amet",
					backgroundImage: {
						component: "Image",
						alt: "Alt",
						file: {
							publicId:
								"CegDEV/CEG-HeroPhoto_93f16f65-d9b4-446b-b59f-11a8d320907e",
						},
						veil: 0,
					},
					data: {
						mode: "manual",
						sources: [{ structuredData: "STUDY" }],
						order: "alpha",
						quantity: 50,
						allLanguages: true,
					},
					hasDistributorData: true,
					elements: [],
				},
			],
		},
		groupingCollection: {
			component: "Section",
			name: "Grouping Collection",
			modules: [
				{
					component: "GroupingCollection",
					title: { content: "Program Title", tag: "h2" },
					elements: [...groupingElements],
				},
			],
		},
		relatedContent: {
			component: "Section",
			name: "Related Content",
			modules: [
				{
					component: "StickyFooterCTA",
					phoneNumber: "+34 91 514 53 30",
					button: {
						component: "Button",
						buttonText: "Solicita información",
						appearance: "primary",
						size: "medium",
						url: {
							href: "",
							linkTo: null,
							newTab: false,
							noFollow: false,
						},
					},
				},
			],
		},
	},
	thumbnails: {},
};

const extraFields = [
	{
		title: "Grouping Collection",
		type: "ComponentArray",
		maxItems: 1,
		whiteList: ["GroupingCollection"],
		key: "groupingCollection",
	},
	{
		title: "Related Content",
		type: "ComponentArray",
		maxItems: null,
		whiteList: [...basicModuleList, ...additionalModuleList],
		key: "relatedContent",
	},
];

const schema = mountStudySchema({ schemaBase });
schema.content = [...schema.content, ...extraFields];
export default createSchema({ ...schema });

import { mapLibrary } from "@adapters";
import { Component as CoreComponent } from "@griddo/core";
import * as React from "react";

const AcademicDocuments = React.lazy(() =>
	import(
		"./AcademicDocuments" /* webpackChunkName: "component---src-AcademicDocuments-js" */
	)
);

const AcademicStatus = React.lazy(() =>
	import(
		"@modules/PT_InfoRequestForm/AcademicStatus" /* webpackChunkName: "module---src-AwardsDistributor-js" */
	)
);

const AccordionElement = React.lazy(() =>
	import(
		"./AccordionElement" /* webpackChunkName: "component---src-AccordionElement-js" */
	)
);

const AgendaElement = React.lazy(() =>
	import(
		"./AgendaElement" /* webpackChunkName: "component---src-AgendaElement-js" */
	)
);

const AnchorWrapper = React.lazy(() =>
	import(
		"./AnchorWrapper" /* webpackChunkName: "component---src-AnchorWrapper-js" */
	)
);
const ArtDirectionImage = React.lazy(() =>
	import(
		"./ArtDirectionImage" /* webpackChunkName: "component---src-AnchorWrapper-js" */
	)
);
const ArtDirectionImageHorizontalVertical = React.lazy(() =>
	import(
		"./ArtDirectionImageHorizontalVertical" /* webpackChunkName: "component---src-AnchorWrapper-js" */
	)
);

const AwardsCard = React.lazy(() =>
	import("./AwardsCard" /* webpackChunkName: "component---src-AwardsCard-js" */)
);

const BasicCard = React.lazy(() =>
	import("./BasicCard" /* webpackChunkName: "component---src-BasicCard-js" */)
);

const BlogCard = React.lazy(() =>
	import("./BlogCard" /* webpackChunkName: "component---src-BlogCard-js" */)
);

const BreadCrumb = React.lazy(() =>
	import("./BreadCrumb" /* webpackChunkName: "component---src-BreadCrumb-js" */)
);

const Button = React.lazy(() =>
	import("./Button" /* webpackChunkName: "component---src-Button-js" */)
);

const ButtonToTop = React.lazy(() =>
	import(
		"./ButtonToTop" /* webpackChunkName: "component---src-ButtonToTop-js" */
	)
);

const CheckboxComponent = React.lazy(() =>
	import(
		"./CheckboxComponent" /* webpackChunkName: "component---src-CheckboxComponent-js" */
	)
);

const CloudinaryBackgroundImage = React.lazy(() =>
	import(
		"./CloudinaryBackgroundImage" /* webpackChunkName: "component---src-CloudinaryBackgroundImage-js" */
	)
);

const ContactsBlock = React.lazy(() =>
	import(
		"./ContactsBlock" /* webpackChunkName: "component---src-ContactsBlock-js" */
	)
);

const CypherCard = React.lazy(() =>
	import("./CypherCard" /* webpackChunkName: "component---src-CypherCard-js" */)
);

const DateButton = React.lazy(() =>
	import("./DateButton" /* webpackChunkName: "component---src-DateButton-js" */)
);

const DateSelect = React.lazy(() =>
	import("./DateSelect" /* webpackChunkName: "component---src-DateSelect-js" */)
);

const DescriptionCard = React.lazy(() =>
	import(
		"./DescriptionCard" /* webpackChunkName: "component---src-DescriptionCard-js" */
	)
);

const Discount = React.lazy(() =>
	import("./Discount" /* webpackChunkName: "component---src-Discount-js" */)
);

const DiscountsManager = React.lazy(() =>
	import(
		"./DiscountsManager" /* webpackChunkName: "component---src-DiscountsManager-js" */
	)
);

const DocumentSelect = React.lazy(() =>
	import(
		"./DocumentSelect" /* webpackChunkName: "component---src-DocumentSelect-js" */
	)
);

const DropDownComponent = React.lazy(() =>
	import(
		"./DropDownComponent" /* webpackChunkName: "component---src-DropDownComponent-js" */
	)
);

const DuotoneComponent = React.lazy(() =>
	import(
		"./DuotoneComponent" /* webpackChunkName: "component---src-DuotoneComponent-js" */
	)
);

const ErrorMessage = React.lazy(() =>
	import(
		"./ErrorMessage" /* webpackChunkName: "component---src-ErrorMessage-js" */
	)
);

const EventCard = React.lazy(() =>
	import("./EventCard" /* webpackChunkName: "component---src-EventCard-js" */)
);

const FilterBar = React.lazy(() =>
	import("./FilterBar" /* webpackChunkName: "component---src-FilterBar-js" */)
);

const FilterComponent = React.lazy(() =>
	import(
		"./FilterComponent" /* webpackChunkName: "component---src-FilterComponent-js" */
	)
);

const FormConfirmationDialog = React.lazy(() =>
	import(
		"./FormConfirmationDialog" /* webpackChunkName: "component---src-FormConfirmationDialog-js" */
	)
);

const GroupingElement = React.lazy(() =>
	import(
		"./GroupingElement" /* webpackChunkName: "component---src-GroupingElement-js" */
	)
);

const HighlightedDegreesCard = React.lazy(() =>
	import(
		"./HighlightedDegreesCard" /* webpackChunkName: "component---src-HighlightedDegreesCard-js" */
	)
);

const HorizontalTabElement = React.lazy(() =>
	import(
		"./HorizontalTabElement" /* webpackChunkName: "component---src-HorizontalTabElement-js" */
	)
);

const HowMetUsSelect = React.lazy(() =>
	import(
		"./HowMetUsSelect" /* webpackChunkName: "component---src-HowMetUsSelect-js" */
	)
);

const Icon = React.lazy(() =>
	import("./Icon" /* webpackChunkName: "component---src-Icon-js" */)
);

const IconCard = React.lazy(() =>
	import("./IconCard" /* webpackChunkName: "component---src-IconCard-js" */)
);

const ImageCard = React.lazy(() =>
	import("./ImageCard" /* webpackChunkName: "component---src-ImageCard-js" */)
);

const ImageUpload = React.lazy(() =>
	import(
		"./ImageUpload" /* webpackChunkName: "component---src-ImageUpload-js" */
	)
);

const InfoRequestModal = React.lazy(() =>
	import(
		"./InfoRequestModal" /* webpackChunkName: "component---src-InfoRequestModal-js" */
	)
);

const InputComponent = React.lazy(() =>
	import(
		"./InputComponent" /* webpackChunkName: "component---src-InputComponent-js" */
	)
);

const LanguageSelect = React.lazy(() =>
	import(
		"./LanguageSelect" /* webpackChunkName: "component---src-LanguageSelect-js" */
	)
);

const LanguageSelector = React.lazy(() =>
	import(
		"./LanguageSelector" /* webpackChunkName: "component---src-LanguageSelector-js" */
	)
);

const LanguageSelectorDropdown = React.lazy(() =>
	import(
		"./LanguageSelectorDropdown" /* webpackChunkName: "component---src-LanguageSelectorDropdown-js" */
	)
);

const LdJson = React.lazy(() =>
	import("./LdJson" /* webpackChunkName: "component---src-LdJson-js" */)
);

const LegalText = React.lazy(() =>
	import("./LegalText" /* webpackChunkName: "component---src-LegalText-js" */)
);

const LightboxWrapper = React.lazy(() =>
	import(
		"./LightboxWrapper" /* webpackChunkName: "component---src-LightboxWrapper-js" */
	)
);

const Link = React.lazy(() =>
	import("./Link" /* webpackChunkName: "component---src-Link-js" */)
);

const LinkCard = React.lazy(() =>
	import("./LinkCard" /* webpackChunkName: "component---src-LinkCard-js" */)
);

const LinkableImage = React.lazy(() =>
	import(
		"./LinkableImage" /* webpackChunkName: "component---src-LinkableImage-js" */
	)
);

const Loader = React.lazy(() =>
	import("./Loader.js" /* webpackChunkName: "component---src-Loader.js-js" */)
);

const Markdown = React.lazy(() =>
	import(
		"./Markdown/Markdown" /* webpackChunkName: "component---src-Markdown-js" */
	)
);

/**
 * `<FullMarkdown>`
 *
 * @deprecated This component is deprecated, consider using <Wysiwyg /> instead.
 */
const FullMarkdown = React.lazy(() =>
	import(
		"./Markdown/FullMarkdown" /* webpackChunkName: "component---src-FullMarkdown-js" */
	)
);

const NoStyledMarkdown = React.lazy(() =>
	import(
		"./Markdown/NoStyledMarkdown" /* webpackChunkName: "component---src-NoStyledMarkdown-js" */
	)
);

/**
 * `<MaxWidth>`
 *
 * @deprecated This component is deprecated, consider using <Container /> instead.
 */
const MaxWidth = React.lazy(() =>
	import("./MaxWidth" /* webpackChunkName: "component---src-MaxWidth-js" */)
);

const Modal = React.lazy(() =>
	import("./Modal" /* webpackChunkName: "component---src-Modal-js" */)
);

const ModalsManager = React.lazy(() =>
	import(
		"./ModalsManager" /* webpackChunkName: "component---src-ModalsManager-js" */
	)
);

const NationalitySelect = React.lazy(() =>
	import(
		"./NationalitySelect" /* webpackChunkName: "component---src-NationalitySelect-js" */
	)
);

const NewsCard = React.lazy(() =>
	import("./NewsCard" /* webpackChunkName: "component---src-NewsCard-js" */)
);

const PT_TitleSelect = React.lazy(() =>
	import(
		"./PT_TitleSelect" /* webpackChunkName: "component---src-PT_TitleSelect-js" */
	)
);
const PT_TitleSelectLanding = React.lazy(() =>
	import(
		"./PT_TitleSelectLanding" /* webpackChunkName: "component---src-PT_TitleSelectLanding-js" */
	)
);

const Pagination = React.lazy(() =>
	import("./Pagination" /* webpackChunkName: "component---src-Pagination-js" */)
);

const PersonalDocuments = React.lazy(() =>
	import(
		"./PersonalDocuments" /* webpackChunkName: "component---src-PersonalDocuments-js" */
	)
);

const PhoneAndButton = React.lazy(() =>
	import(
		"./PhoneAndButton" /* webpackChunkName: "component---src-PhoneAndButton-js" */
	)
);

const PhoneInputComponent = React.lazy(() =>
	import(
		"./PhoneInputComponent" /* webpackChunkName: "component---src-PhoneInputComponent-js" */
	)
);

const PhoneNumberElement = React.lazy(() =>
	import(
		"./PhoneNumberElement" /* webpackChunkName: "component---src-PhoneNumberElement-js" */
	)
);

const Portal = React.lazy(() =>
	import("./Portal" /* webpackChunkName: "component---src-Portal-js" */)
);

const PositionSelect = React.lazy(() =>
	import(
		"./PositionSelect" /* webpackChunkName: "component---src-PositionSelect-js" */
	)
);

const ProfessionalDocuments = React.lazy(() =>
	import(
		"./ProfessionalDocuments" /* webpackChunkName: "component---src-ProfessionalDocuments-js" */
	)
);

const ProvinceSelect = React.lazy(() =>
	import(
		"./ProvinceSelect" /* webpackChunkName: "component---src-ProvinceSelect-js" */
	)
);

const QuickFacts = React.lazy(() =>
	import("./QuickFacts" /* webpackChunkName: "component---src-QuickFacts-js" */)
);

const QuickFactsElement = React.lazy(() =>
	import(
		"./QuickFactsElement" /* webpackChunkName: "component---src-QuickFactsElement-js" */
	)
);

const QuickFactConditionalText = React.lazy(() =>
	import(
		"./LandingQuickFacts/QuickFactConditionalText" /* webpackChunkName: "component---src-QuickFactConditionalText-js" */
	)
);

const RelatedProgramsElement = React.lazy(() =>
	import(
		"./RelatedProgramsElement" /* webpackChunkName: "component---src-RelatedProgramsElement-js" */
	)
);

const ResponsiveInfo = React.lazy(() =>
	import(
		"./ResponsiveInfo" /* webpackChunkName: "component---src-ResponsiveInfo-js" */
	)
);

const SmallCard = React.lazy(() =>
	import("./SmallCard" /* webpackChunkName: "component---src-SmallCard-js" */)
);

const SolidCard = React.lazy(() =>
	import("./SolidCard" /* webpackChunkName: "component---src-SolidCard-js" */)
);

const StaffCardLanding = React.lazy(() =>
	import(
		"./StaffCardLanding" /* webpackChunkName: "component---src-StaffCardLanding-js" */
	)
);

const StudyAgregatorCard = React.lazy(() =>
	import(
		"./StudyAgregatorCard" /* webpackChunkName: "component---src-StudyAgregatorCard-js" */
	)
);

const StudyAgregatorElement = React.lazy(() =>
	import(
		"./StudyAgregatorElement" /* webpackChunkName: "component---src-StudyAgregatorElement-js" */
	)
);

const StudyCard = React.lazy(() =>
	import("./StudyCard" /* webpackChunkName: "component---src-StudyCard-js" */)
);

const StudyInfoBlock = React.lazy(() =>
	import(
		"./StudyInfoBlock" /* webpackChunkName: "component---src-StudyInfoBlock-js" */
	)
);

const StudyInfoBlockButton = React.lazy(() =>
	import(
		"./StudyInfoBlockButton" /* webpackChunkName: "component---src-StudyInfoBlockButton-js" */
	)
);

const StudySelect = React.lazy(() =>
	import(
		"./StudySelect" /* webpackChunkName: "component---src-StudySelect-js" */
	)
);

const TableRow = React.lazy(() =>
	import("./TableRow" /* webpackChunkName: "component---src-TableRow-js" */)
);

const TeacherCard = React.lazy(() =>
	import(
		"./TeacherCard" /* webpackChunkName: "component---src-TeacherCard-js" */
	)
);

const TestimonialsCard = React.lazy(() =>
	import(
		"./TestimonialsCard" /* webpackChunkName: "component---src-TestimonialsCard-js" */
	)
);

const TestimonialsCardLanding = React.lazy(() =>
	import(
		"./TestimonialsCardLanding" /* webpackChunkName: "component---src-TestimonialsCardLanding-js" */
	)
);

const Text = React.lazy(() =>
	import("./Text" /* webpackChunkName: "component---src-Text-js" */)
);

const TitleSelect = React.lazy(() =>
	import(
		"./TitleSelect" /* webpackChunkName: "component---src-TitleSelect-js" */
	)
);

const UniversitySelect = React.lazy(() =>
	import(
		"./UniversitySelect" /* webpackChunkName: "component---src-UniversitySelect-js" */
	)
);

const Video = React.lazy(() =>
	import("./Video" /* webpackChunkName: "component---src-Video-js" */)
);

const VideoModal = React.lazy(() =>
	import("./VideoModal" /* webpackChunkName: "component---src-VideoModal-js" */)
);

const VideoTitle = React.lazy(() =>
	import("./VideoTitle" /* webpackChunkName: "component---src-VideoTitle-js" */)
);

const FAQElement = React.lazy(() =>
	import("./FAQElement" /* webpackChunkName: "component---src-FAQElement-js" */)
);
const LandingEventCard = React.lazy(() =>
	import(
		"./LandingEventCard" /* webpackChunkName: "component---src-LandingEventCard-js" */
	)
);

const LandingStudyCard = React.lazy(() =>
	import(
		"./LandingStudyCard" /* webpackChunkName: "component---src-LandingStudyCard-js" */
	)
);

const SelectComponent = React.lazy(() =>
	import(
		"./SelectComponent" /* webpackChunkName: "component---src-SelectComponent-js" */
	)
);

const LandingQuickFacts = React.lazy(() =>
	import(
		"./LandingQuickFacts" /* webpackChunkName: "component---src-LandingQuickFacts-js" */
	)
);

const Skeleton = React.lazy(() =>
	import("./Skeleton" /* webpackChunkName: "component---src-Skeleton-js" */)
);
const FormTypePrivacyPolicy = React.lazy(() =>
	import(
		"./FormTypePrivacyPolicy" /* webpackChunkName: "component---src-FormTypePrivacyPolicy-js" */
	)
);

const LandingFormContainer = React.lazy(() =>
	import(
		"./LandingFormContainer" /* webpackChunkName: "component---src-LandingFormContainer-js" */
	)
);

const CheckboxListComponent = React.lazy(() =>
	import(
		"./CheckboxListComponent" /* webpackChunkName: "component---src-CheckboxListComponent-js" */
	)
);

const FooterCookiesLink = React.lazy(() =>
	import(
		"./FooterCookiesLink" /* webpackChunkName: "component---src-FooterCookiesLink-js" */
	)
);

const FilteredList = React.lazy(() =>
	import(
		"@components/FilteredList" /* webpackChunkName: "component---src-FilteredList-js" */
	)
);

const PaginationFilteredList = React.lazy(() =>
	import(
		"@components/FilteredList/Pagination" /* webpackChunkName: "component---src-PaginationFilteredList-js" */
	)
);

const Filters = React.lazy(() =>
	import(
		"@components/FilteredList/Filters" /* webpackChunkName: "component---src-Filters-js" */
	)
);

const List = React.lazy(() =>
	import(
		"@components/FilteredList/List" /* webpackChunkName: "component---src-List-js" */
	)
);

const PaginationAndFilters = React.lazy(() =>
	import(
		"./PaginationAndFilters" /* webpackChunkName: "component---src-PaginationAndFilters-js" */
	)
);

const StudyList = React.lazy(() =>
	import("./StudyList" /* webpackChunkName: "component---src-StudyList-js" */)
);

const FormTypeStudyDistributor = React.lazy(() =>
	import(
		"./FormTypeStudyDistributor" /* webpackChunkName: "component---src-FormTypeStudyDistributor-js" */
	)
);

const ImageTrans = React.lazy(() =>
	import("./ImageTrans" /* webpackChunkName: "component---src-ImageTrans-js" */)
);

const LdJsonCollectionPage = React.lazy(() =>
	import(
		"./LdJsonCollectionPage" /* webpackChunkName: "component---src-LdJsonCollectionPage-js" */
	)
);
const LdJsonArticle = React.lazy(() =>
	import(
		"./LdJsonArticle" /* webpackChunkName: "component---src-LdJsonArticle-js" */
	)
);

const LdJsonListItem = React.lazy(() =>
	import(
		"./LdJsonListItem" /* webpackChunkName: "component---src-LdJsonListItem-js" */
	)
);

const EXPORTS = {
	AcademicDocuments,
	AcademicStatus,
	AccordionElement,
	AgendaElement,
	AnchorWrapper,
	ArtDirectionImage,
	ArtDirectionImageHorizontalVertical,
	AwardsCard,
	BasicCard,
	BlogCard,
	BreadCrumb,
	Button,
	ButtonToTop,
	CheckboxComponent,
	CheckboxListComponent,
	CloudinaryBackgroundImage,
	ContactsBlock,
	CypherCard,
	DateButton,
	DateSelect,
	DescriptionCard,
	Discount,
	DiscountsManager,
	DocumentSelect,
	DropDownComponent,
	DuotoneComponent,
	ErrorMessage,
	EventCard,
	FAQElement,
	FilterBar,
	FilterComponent,
	FilteredList,
	Filters,
	FooterCookiesLink,
	FormConfirmationDialog,
	FormTypePrivacyPolicy,
	FullMarkdown,
	GroupingElement,
	HighlightedDegreesCard,
	HorizontalTabElement,
	HowMetUsSelect,
	Icon,
	IconCard,
	ImageCard,
	ImageUpload,
	InfoRequestModal,
	InputComponent,
	LandingEventCard,
	LandingFormContainer,
	LandingQuickFacts,
	LandingStudyCard,
	LanguageSelect,
	LanguageSelector,
	LanguageSelectorDropdown,
	LdJson,
	LegalText,
	LightboxWrapper,
	Link,
	LinkableImage,
	LinkCard,
	List,
	Loader,
	Markdown,
	MaxWidth,
	Modal,
	ModalsManager,
	NationalitySelect,
	NewsCard,
	NoStyledMarkdown,
	Pagination,
	PaginationAndFilters,
	PaginationFilteredList,
	PersonalDocuments,
	PhoneAndButton,
	PhoneInputComponent,
	PhoneNumberElement,
	Portal,
	PositionSelect,
	ProfessionalDocuments,
	ProvinceSelect,
	PT_TitleSelect,
	PT_TitleSelectLanding,
	QuickFactConditionalText,
	QuickFacts,
	QuickFactsElement,
	RelatedProgramsElement,
	ResponsiveInfo,
	SelectComponent,
	Skeleton,
	SmallCard,
	SolidCard,
	StaffCardLanding,
	StudyAgregatorCard,
	StudyAgregatorElement,
	StudyCard,
	StudyInfoBlock,
	StudyInfoBlockButton,
	StudyList,
	StudySelect,
	TableRow,
	TeacherCard,
	TestimonialsCard,
	TestimonialsCardLanding,
	Text,
	TitleSelect,
	UniversitySelect,
	Video,
	VideoModal,
	VideoTitle,
	FormTypeStudyDistributor,
	ImageTrans,
	LdJsonCollectionPage,
};

const components = mapLibrary({
	...EXPORTS,
});

const Component = (props) => (
	<CoreComponent libComponents={components} {...props} />
);

export default EXPORTS;

export {
	AcademicDocuments,
	AcademicStatus,
	AccordionElement,
	AgendaElement,
	AnchorWrapper,
	ArtDirectionImage,
	ArtDirectionImageHorizontalVertical,
	AwardsCard,
	BasicCard,
	BlogCard,
	BreadCrumb,
	Button,
	ButtonToTop,
	CheckboxComponent,
	CheckboxListComponent,
	CloudinaryBackgroundImage,
	ContactsBlock,
	CypherCard,
	DateButton,
	DateSelect,
	DescriptionCard,
	Discount,
	DiscountsManager,
	DocumentSelect,
	DropDownComponent,
	DuotoneComponent,
	ErrorMessage,
	EventCard,
	FAQElement,
	FilterBar,
	FilterComponent,
	FilteredList,
	Filters,
	FooterCookiesLink,
	FormConfirmationDialog,
	FormTypePrivacyPolicy,
	FullMarkdown,
	GroupingElement,
	HighlightedDegreesCard,
	HorizontalTabElement,
	HowMetUsSelect,
	Icon,
	IconCard,
	ImageCard,
	ImageUpload,
	InfoRequestModal,
	InputComponent,
	LandingEventCard,
	LandingFormContainer,
	LandingQuickFacts,
	LandingStudyCard,
	LanguageSelect,
	LanguageSelector,
	LanguageSelectorDropdown,
	LdJson,
	LegalText,
	LightboxWrapper,
	Link,
	LinkableImage,
	LinkCard,
	List,
	Loader,
	Markdown,
	MaxWidth,
	Modal,
	ModalsManager,
	NationalitySelect,
	NewsCard,
	NoStyledMarkdown,
	Pagination,
	PaginationAndFilters,
	PaginationFilteredList,
	PersonalDocuments,
	PhoneAndButton,
	PhoneInputComponent,
	PhoneNumberElement,
	Portal,
	PositionSelect,
	ProfessionalDocuments,
	ProvinceSelect,
	PT_TitleSelect,
	PT_TitleSelectLanding,
	QuickFactConditionalText,
	QuickFacts,
	QuickFactsElement,
	RelatedProgramsElement,
	ResponsiveInfo,
	SelectComponent,
	Skeleton,
	SmallCard,
	SolidCard,
	StaffCardLanding,
	StudyAgregatorCard,
	StudyAgregatorElement,
	StudyCard,
	StudyInfoBlock,
	StudyInfoBlockButton,
	StudyList,
	StudySelect,
	TableRow,
	TeacherCard,
	TestimonialsCard,
	TestimonialsCardLanding,
	Text,
	TitleSelect,
	UniversitySelect,
	Video,
	VideoModal,
	VideoTitle,
	FormTypeStudyDistributor,
	ImageTrans,
	LdJsonCollectionPage,
	LdJsonArticle,
	LdJsonListItem,
};

export { Component };

import { basicModuleList, additionalModuleList } from "@schemas/modules";

export default {
	schemaType: "template",
	displayName: "Award Detail",
	component: "AwardDetail",
	dataPacks: ["AWARDS"],
	type: { label: "Awards", value: "awards", mode: "detail" },
	content: [
		{
			title: "Hero Section",
			type: "ComponentArray",
			maxItems: 1,
			whiteList: ["HeroHome", "HeroStudy", "InnerTitle"],
			key: "heroSection",
		},
		{
			title: "Award Title",
			type: "HeadingField",
			humanReadable: true,
			key: "awardTitle",
			advanced: true,
			mandatory: true,
			hideable: false,
			default: { tag: "h2", content: "Title" },
			options: [
				{ value: "h1", label: "H1" },
				{ value: "h2", label: "H2" },
				{ value: "h3", label: "H3" },
				{ value: "h4", label: "H4" },
				{ value: "span", label: "span" },
			],
		},
		{
			title: "Campaign ID",
			type: "TextField",
			key: "campaignId",
			mandatory: false,
			hideable: false,
		},
		{
			title: "Email",
			type: "TextField",
			key: "email",
			mandatory: true,
			hideable: false,
		},
		{
			title: "Primer premio",
			type: "TextField",
			humanReadable: true,
			key: "primerPremio",
		},
		{
			title: "Grouping Collection",
			type: "ComponentArray",
			maxItems: 1,
			whiteList: ["GroupingCollection"],
			key: "groupingCollection",
		},
		{
			title: "Related Content",
			type: "ComponentArray",
			maxItems: null,
			whiteList: [...basicModuleList, ...additionalModuleList],
			key: "relatedContent",
		},
	],

	default: {
		type: "template",
		templateType: "AwardDetail",
		heroSection: {
			component: "Section",
			name: "Hero Section",
			modules: [
				{
					component: "InnerTitle",
					title: {
						content: "Title",
						tag: "h4",
					},
					subtitle: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
					backgroundImage: {
						component: "Image",
						alt: "Alt",
						file: {
							publicId:
								"CegDEV/CEG-HeroPhoto_93f16f65-d9b4-446b-b59f-11a8d320907e",
						},
						veil: 0,
					},
					icon: false,
					breadcrumbsOnly: false,
				},
			],
		},
		awardTitle: { content: "Lorem Ipsum", tag: "h2" },
		campaignId: "",
		email: "example@email.com",
		primerPremio: "",
		groupingCollection: {
			component: "Section",
			name: "Grouping Collection",
			modules: [
				{
					component: "GroupingCollection",
					title: { content: "Program Title", tag: "h2" },
					elements: [
						{
							component: "GroupingElement",
							title: "Section 1",
							componentModules: [
								{
									component: "Introduction",
									title: { content: "Introduction title text", tag: "h2" },
									subtitle: "Introduction subtitle text",
									detail:
										"Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi.",
									button: {
										component: "Button",
										buttonText: "See more",
										appearance: "link",
										size: "small",
										url: {
											href: "",
											linkTo: null,
											newTab: false,
											noFollow: false,
										},
									},
									layout: "L001",
								},
								{
									component: "BasicContent",
									title: { content: "Basic content title text", tag: "h2" },
									subtitle: "Subtitle text",
									detail: "Detail text",
									image: {
										component: "Image",
										alt: "Alt",
										file: {
											publicId:
												"CegDEV/CEG-HeroPhoto_93f16f65-d9b4-446b-b59f-11a8d320907e",
										},
									},
									imageText: "Image detail",
									button: {
										component: "Button",
										buttonText: "Link",
										appearance: "link",
										size: "medium",
										url: {
											href: "",
											linkTo: null,
											newTab: false,
											noFollow: false,
										},
									},
									layout: "L001",
								},
								{
									component: "CypherCollection",
									elements: [
										{
											component: "CypherCard",
											number: "0",
											subtitle: "Lorem ipsum",
											description: "Lorem ipsum dolor sit amet",
										},
										{
											component: "CypherCard",
											number: "0",
											subtitle: "Lorem ipsum",
											description: "Lorem ipsum dolor sit amet",
										},
										{
											component: "CypherCard",
											number: "0",
											subtitle: "Lorem ipsum",
											description: "Lorem ipsum dolor sit amet",
										},
									],
								},
							],
						},
						{
							component: "GroupingElement",
							title: "Section 2",
							componentModules: [
								{
									component: "FeaturedBlock",
									title: {
										content:
											"Lorem ipsum dolor sit amet, consectetuer adipiscing elit",
										tag: "h2",
									},
									theme: "default",
								},
								{
									component: "HighLightedCTA",
									icon: {
										alt: "",
										publicId:
											"CegDEV/CEG-HeroPhoto_93f16f65-d9b4-446b-b59f-11a8d320907e",
									},
									title: {
										content: "Title",
										tag: "h3",
									},
									subtitle: "Lorem ipsum",
									text: "Lorem ipsum",
									button: {
										component: "Button",
										buttonText: "Lorem Ipsum",
										appearance: "secondary",
										size: "big",
										url: {
											href: "",
											linkTo: null,
											newTab: false,
											noFollow: false,
										},
									},
								},
								{
									component: "HighlightedDownload",
									title: { content: "Title", tag: "h3" },
									subtitle: "Lorem Ipsum",
									button: {
										component: "Button",
										buttonText: "Descargar",
										appearance: "primary",
										size: "small",
										url: {
											href: "",
											linkTo: null,
											newTab: false,
											noFollow: false,
										},
									},
								},
							],
						},
						{
							component: "GroupingElement",
							title: "Section 3",
							componentModules: [
								{
									component: "BasicContent",
									title: { content: "Basic content title text", tag: "h2" },
									subtitle: "Subtitle text",
									detail: "Detail text",
									image: {
										component: "Image",
										alt: "Alt",
										file: {
											publicId:
												"CegDEV/CEG-HeroPhoto_93f16f65-d9b4-446b-b59f-11a8d320907e",
										},
									},
									imageText: "Image detail",
									button: {
										component: "Button",
										buttonText: "Link",
										appearance: "link",
										size: "medium",
										url: {
											href: "",
											linkTo: null,
											newTab: false,
											noFollow: false,
										},
									},
									layout: "L001",
								},
								{
									component: "CardCollection",
									title: { content: "Title", tag: "h2" },
									kind: "ImageCard",
									elements: [
										{
											component: "ImageCard",
											file: {
												publicId:
													"CegDEV/CEG-HeroPhoto_93f16f65-d9b4-446b-b59f-11a8d320907e",
											},
											name: "Lorem Ipsum",
											shortInfo: "Lorem Ipsum",
											degree: "Lorem ipsum dolor sit amet consectetur",
											title:
												"Rutrum praesent vel metus accumsan fames nam libero molestie egestas ridiculus.",
											description:
												"“ELorem ipsum dolor sit amet consectetur adipiscing elit hac vivamus, vel senectus ligula nostra dapibus ultrices accumsan est, metus a eleifend sociosqu quis urna id feugiat. Rutrum praesent vel metus accumsan fames nam libero molestie egestas ridiculus, vulputate sociis cras quisque sagittis quis scelerisque curae orci hac lobortis, ut pellentesque vehicula non nunc viverra malesuada porta torquent. Dapibus vestibulum purus ullamcorper parturient fermentum arcu phasellus netus egestas vel viverra, penatibus interdum semper dis himenaeos diam senectus justo laoreet dignissim, facilisis est aptent ante fringilla maecenas libero rhoncus praesent quam.”",
										},
										{
											component: "ImageCard",
											file: {
												publicId:
													"CegDEV/CEG-HeroPhoto_93f16f65-d9b4-446b-b59f-11a8d320907e",
											},
											name: "Lorem Ipsum",
											shortInfo: "Lorem Ipsum",
											degree: "Lorem ipsum dolor sit amet consectetur",
											title:
												"Rutrum praesent vel metus accumsan fames nam libero molestie egestas ridiculus.",
											description:
												"“ELorem ipsum dolor sit amet consectetur adipiscing elit hac vivamus, vel senectus ligula nostra dapibus ultrices accumsan est, metus a eleifend sociosqu quis urna id feugiat. Rutrum praesent vel metus accumsan fames nam libero molestie egestas ridiculus, vulputate sociis cras quisque sagittis quis scelerisque curae orci hac lobortis, ut pellentesque vehicula non nunc viverra malesuada porta torquent. Dapibus vestibulum purus ullamcorper parturient fermentum arcu phasellus netus egestas vel viverra, penatibus interdum semper dis himenaeos diam senectus justo laoreet dignissim, facilisis est aptent ante fringilla maecenas libero rhoncus praesent quam.”",
										},
									],
									layout: "L004",
									button: {
										component: "Button",
										buttonText: "Link",
										appearance: "link",
										size: "small",
										url: {
											href: "",
											linkTo: null,
											newTab: false,
											noFollow: false,
										},
									},
								},
								{
									component: "AddressCollection",
									title: { content: "Title", tag: "h3" },
									subtitle:
										"Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
									address: "Lorem ipsum dolor sit amet",
									email: "email@email.com",
									phone: "+34 (0) 00 00 00 00 00",
									button: {
										component: "Button",
										buttonText: "Cómo Llegar",
										appearance: "secondary",
										size: "big",
										url: {
											href: "",
											linkTo: null,
											newTab: false,
											noFollow: false,
										},
									},
									backgroundImage: {
										component: "Image",
										alt: "Alt",
										file: {
											publicId:
												"CegDEV/CEG-HeroPhoto_93f16f65-d9b4-446b-b59f-11a8d320907e",
										},
										veil: 0,
									},
								},
							],
						},
					],
				},
			],
		},
		relatedContent: {
			component: "Section",
			name: "Related Content",
			modules: [
				{
					component: "StickyFooterCTA",
					phoneNumber: "+34 91 514 53 30",
					button: {
						component: "Button",
						buttonText: "Solicita información",
						appearance: "primary",
						size: "medium",
						url: {
							href: "",
							linkTo: null,
							newTab: false,
							noFollow: false,
						},
					},
				},
			],
		},
	},

	thumbnails: {},
};

export default {
	COURSE_MODE: {
		title: "Course Mode",
		dataPacks: ["STUDY"],
		local: false,
		taxonomy: false,
		fromPage: false,
		translate: false,
		editable: false,
		schema: {
			fields: [
				{
					title: "Title",
					type: "TextField",
					humanReadable: true,
					key: "title",
				},
				{
					title: "Code",
					type: "TextField",
					key: "code",
				},
			],
		},
		clone: null,
		defaultValues: null,
	},
};

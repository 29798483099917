import CLOUDINARY_BASE_UPLOAD_URL from "@constants/cloudinary/base-upload-url";
import { createSchema } from "@utils/index";

// eslint-disable-next-line @typescript-eslint/no-var-requires
const { instance } = require("../../../../instances/current.json");

export default createSchema({
	schemaType: "module",
	displayName: "HighLighted CTA",
	component: "HighLightedCTA",
	category: "featured",
	dataPacks: null,
	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "Icon",
					type: "ImageField",
					key: "icon",
					mandatory: false,
				},
				{
					title: "Title",
					type: "HeadingField",
					humanReadable: true,
					key: "title",
					advanced: true,
					mandatory: false,
					default: { tag: "h2", content: "Title" },
					options: [
						{ value: "h1", label: "H1" },
						{ value: "h2", label: "H2" },
						{ value: "h3", label: "H3" },
						{ value: "h4", label: "H4" },
						{ value: "span", label: "span" },
					],
				},
				{
					title: "Subtitle",
					type: "TextField",
					key: "subtitle",
					mandatory: false,
					humanReadable: true,
				},
				{
					title: "Text",
					type: "TextField",
					key: "text",
					mandatory: false,
					humanReadable: true,
				},
				...(instance.includes("CEG")
					? [
							{
								title: "Add form",
								type: "ConditionalField",
								key: "addForm",
								mandatory: true,
								options: [
									{
										value: true,
										title: "Yes",
										name: "yes",
									},
									{
										value: false,
										title: "No",
										name: "no",
									},
								],
								fields: [
									{
										title: "Form type",
										type: "ConditionalField",
										key: "formType",
										mandatory: true,
										condition: true,
										options: [
											{
												value: true,
												title: "Admission Form",
												name: "yes",
											},
											{
												value: false,
												title: "Award Form",
												name: "no",
											},
										],
										fields: [
											{
												title: "Email",
												type: "TextField",
												key: "email",
												mandatory: true,
												hideable: false,
												condition: false,
											},
											{
												title: "Subject",
												type: "TextField",
												key: "subject",
												mandatory: true,
												hideable: false,
												condition: false,
												humanReadable: true,
											},
											{
												title: "Award Title",
												type: "TextField",
												key: "awardTitle",
												mandatory: true,
												hideable: false,
												condition: false,
												humanReadable: true,
											},
											{
												title: "Campaign ID",
												type: "TextField",
												key: "campaignId",
												mandatory: false,
												hideable: false,
												condition: false,
											},
										],
									},
								],
							},
					  ]
					: instance.includes("IPAM") || instance.includes("IADE")
					? [
							{
								title: "Add form IPAM/IADE",
								type: "ConditionalField",
								key: "addForm",
								mandatory: true,
								options: [
									{
										value: true,
										title: "Yes",
										name: "yes",
									},
									{
										value: false,
										title: "No",
										name: "no",
									},
								],
							},
					  ]
					: []),
				{
					title: "Button",
					type: "ComponentContainer",
					whiteList: ["Button"],
					key: "button",
					mandatory: true,
				},
			],
		},
		{
			title: "config",
			fields: [
				{
					title: "Layout",
					key: "layout",
					type: "VisualUniqueSelection",
					options: [
						{
							value: "L001",
							img: `${CLOUDINARY_BASE_UPLOAD_URL}/layouts/HighLightedCTA/layout001_x2.png`,
						},
						{
							value: "L002",
							img: `${CLOUDINARY_BASE_UPLOAD_URL}/layouts/HighLightedCTA/layout002_x2.png`,
						},
						{
							value: "L003",
							img: `${CLOUDINARY_BASE_UPLOAD_URL}/layouts/HighLightedCTA/layout003_x2.png`,
						},
					],
					columns: 2,
				},
				{
					title: "Style",
					key: "theme",
					type: "VisualUniqueSelection",
					mandatory: true,
					options: [
						{
							theme: "garrigues-theme",
							options: [
								{
									value: "default",
									img: `${CLOUDINARY_BASE_UPLOAD_URL}/themes/garrigues-theme/default.png`,
								},
								{
									value: "accentLight",
									img: `${CLOUDINARY_BASE_UPLOAD_URL}/themes/garrigues-theme/accentLight.png`,
								},
							],
						},
						{
							theme: "eeg-theme",
							options: [
								{
									value: "default",
									img: `${CLOUDINARY_BASE_UPLOAD_URL}/themes/eeg-theme/default.png`,
								},
								{
									value: "defaultAlt",
									img: `${CLOUDINARY_BASE_UPLOAD_URL}/themes/eeg-theme/defaultAlt.png`,
								},
								{
									value: "accent",
									img: `${CLOUDINARY_BASE_UPLOAD_URL}/themes/eeg-theme/accent.png`,
								},
								{
									value: "accentAlt",
									img: `${CLOUDINARY_BASE_UPLOAD_URL}/themes/eeg-theme/accentAlt.png`,
								},
								{
									value: "accentLight",
									img: `${CLOUDINARY_BASE_UPLOAD_URL}/themes/eeg-theme/accentLight.png`,
								},
							],
						},
						{
							theme: "ipam-theme",
							options: [
								{
									value: "default",
									img: `${CLOUDINARY_BASE_UPLOAD_URL}/themes/ipam-theme/default.png`,
								},
								{
									value: "defaultAlt",
									img: `${CLOUDINARY_BASE_UPLOAD_URL}/themes/ipam-theme/defaultAlt.png`,
								},
								{
									value: "accent",
									img: `${CLOUDINARY_BASE_UPLOAD_URL}/themes/ipam-theme/accent.png`,
								},
								{
									value: "accentAlt",
									img: `${CLOUDINARY_BASE_UPLOAD_URL}/themes/ipam-theme/accentAlt.png`,
								},
								{
									value: "accentLight",
									img: `${CLOUDINARY_BASE_UPLOAD_URL}/themes/ipam-theme/accentLight.png`,
								},
							],
						},
						{
							theme: "iade-theme",
							options: [
								{
									value: "default",
									img: `${CLOUDINARY_BASE_UPLOAD_URL}/themes/iade-theme/default.png`,
								},
								{
									value: "defaultAlt",
									img: `${CLOUDINARY_BASE_UPLOAD_URL}/themes/iade-theme/defaultAlt.png`,
								},
								{
									value: "accent",
									img: `${CLOUDINARY_BASE_UPLOAD_URL}/themes/iade-theme/accent.png`,
								},
								{
									value: "accentAlt",
									img: `${CLOUDINARY_BASE_UPLOAD_URL}/themes/iade-theme/accentAlt.png`,
								},
								{
									value: "accentLight",
									img: `${CLOUDINARY_BASE_UPLOAD_URL}/themes/iade-theme/accentLight.png`,
								},
							],
						},
						{
							theme: "iade-es-theme",
							options: [
								{
									value: "default",
									img: `${CLOUDINARY_BASE_UPLOAD_URL}/themes/iade-es-theme/default.png`,
								},
								{
									value: "defaultAlt",
									img: `${CLOUDINARY_BASE_UPLOAD_URL}/themes/iade-es-theme/defaultAlt.png`,
								},
								{
									value: "accent",
									img: `${CLOUDINARY_BASE_UPLOAD_URL}/themes/iade-es-theme/accent.png`,
								},
								{
									value: "accentAlt",
									img: `${CLOUDINARY_BASE_UPLOAD_URL}/themes/iade-es-theme/accentAlt.png`,
								},
							],
						},
					],
					columns: 8,
				},
			],
		},
	],

	default: {
		component: "HighLightedCTA",
		addForm: true,
		formType: true,
		email: "example@email.com",
		subject: "Lorem Ipsum",
		awardTitle: "Lorem Ipsum",
		campaignId: "",
		icon: {
			alt: "",
			publicId: "CegDEV/CEG-HeroPhoto_93f16f65-d9b4-446b-b59f-11a8d320907e",
		},
		title: {
			content: "Title",
			tag: "h3",
		},
		subtitle: "Lorem ipsum",
		text: "Lorem ipsum",
		button: {
			component: "Button",
			buttonText: "Lorem Ipsum",
			appearance: "secondary",
			size: "medium",
			url: {
				href: "",
				linkTo: null,
				newTab: false,
				noFollow: false,
			},
		},
		theme: "default",
	},
	thumbnails: {
		"1x": `${CLOUDINARY_BASE_UPLOAD_URL}/thumbnails/HighLightedCTA_x2.png`,
		"2x": `${CLOUDINARY_BASE_UPLOAD_URL}/thumbnails/HighLightedCTA_x2.png`,
	},
});

import { instance } from "@instance-config";
import { basicModuleList, additionalModuleList } from "@schemas/modules";

export default {
	schemaType: "template",
	displayName: "News List",
	component: "NewsList",
	dataPacks: ["NEWS"],
	type: { label: "News", value: "news", mode: "list" },
	content: [
		{
			title: "Hero Section",
			type: "ComponentArray",
			maxItems: 1,
			whiteList: ["HeroHome", "HeroStudy", "InnerTitle"],
			key: "heroSection",
		},
		{
			title: "News Title",
			type: "HeadingField",
			humanReadable: true,
			key: "newsTitle",
			advanced: true,
			hideable: true,
			default: { tag: "h2", content: "Title" },
			options: [
				{ value: "h1", label: "H1" },
				{ value: "h2", label: "H2" },
				{ value: "h3", label: "H3" },
				{ value: "h4", label: "H4" },
				{ value: "span", label: "span" },
			],
		},
		{
			title: "Items per page",
			type: "NumberField",
			key: "itemsPerPage",
			mandatory: true,
		},
		{
			title: "News",
			type: "ReferenceField",
			sources: [{ structuredData: "NEWS" }],
			key: "data",
		},
		{
			title: "Main Section",
			type: "ComponentArray",
			maxItems: null,
			whiteList: [...basicModuleList, ...additionalModuleList],
			key: "mainSection",
		},
	],

	default: {
		component: "NewsList",
		type: "template",
		templateType: "NewsList",
		heroSection: {
			component: "Section",
			name: "Hero Section",
			modules: [
				{
					component: "InnerTitle",
					title: {
						content: "Title",
						tag: "h4",
					},
					subtitle: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
					backgroundImage: {
						component: "Image",
						alt: "Alt",
						file: {
							publicId:
								"CegDEV/CEG-HeroPhoto_93f16f65-d9b4-446b-b59f-11a8d320907e",
						},
						veil: 0,
					},
					icon: false,
					breadcrumbsOnly: false,
				},
			],
		},
		newsTitle: { content: "Title", tag: "h2" },
		data: {
			mode: "auto",
			sources: [{ structuredData: "NEWS" }],
			order: "publicationDate-DESC",
			quantity: 0,
		},
		hasDistributorData: true,
		mainSection: {
			component: "Section",
			name: "Main Section",
			modules: [
				{
					component: "CardCollection",
					title: { content: "Title", tag: "h2" },
					kind: "BasicCard",
					elements: [
						{
							component: "BasicCard",
							title: "Lorem ipsum",
							description:
								"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu. ",
							file: {
								alt: "",
								publicId:
									"CegDEV/CEG-HeroPhoto_93f16f65-d9b4-446b-b59f-11a8d320907e",
								url: "",
							},
							veil: 40,
							button: {
								component: "Button",
							},
						},
						{
							component: "BasicCard",
							title: "Lorem ipsum",
							description:
								"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu. ",
							file: {
								alt: "",
								publicId:
									"CegDEV/CEG-HeroPhoto_93f16f65-d9b4-446b-b59f-11a8d320907e",
								url: "",
							},
							veil: 40,
							button: {
								component: "Button",
							},
						},
					],
					button: {
						component: "Button",
						buttonText: "Link",
						appearance: "link",
						size: "small",
						url: {
							href: "",
							linkTo: null,
							newTab: false,
							noFollow: false,
						},
					},
					layout: "L004",
				},
				{
					component: "CardCollection",
					title: { content: "Title", tag: "h2" },
					kind: "ImageCard",
					elements: [
						{
							component: "ImageCard",
							file: {
								publicId:
									"CegDEV/CEG-HeroPhoto_93f16f65-d9b4-446b-b59f-11a8d320907e",
							},
							name: "Lorem Ipsum",
							shortInfo: "Lorem Ipsum",
							degree: "Lorem ipsum dolor sit amet consectetur",
							title:
								"Rutrum praesent vel metus accumsan fames nam libero molestie egestas ridiculus.",
							description:
								"“ELorem ipsum dolor sit amet consectetur adipiscing elit hac vivamus, vel senectus ligula nostra dapibus ultrices accumsan est, metus a eleifend sociosqu quis urna id feugiat. Rutrum praesent vel metus accumsan fames nam libero molestie egestas ridiculus, vulputate sociis cras quisque sagittis quis scelerisque curae orci hac lobortis, ut pellentesque vehicula non nunc viverra malesuada porta torquent. Dapibus vestibulum purus ullamcorper parturient fermentum arcu phasellus netus egestas vel viverra, penatibus interdum semper dis himenaeos diam senectus justo laoreet dignissim, facilisis est aptent ante fringilla maecenas libero rhoncus praesent quam.”",
						},
						{
							component: "ImageCard",
							file: {
								publicId:
									"CegDEV/CEG-HeroPhoto_93f16f65-d9b4-446b-b59f-11a8d320907e",
							},
							name: "Lorem Ipsum",
							shortInfo: "Lorem Ipsum",
							degree: "Lorem ipsum dolor sit amet consectetur",
							title:
								"Rutrum praesent vel metus accumsan fames nam libero molestie egestas ridiculus.",
							description:
								"“ELorem ipsum dolor sit amet consectetur adipiscing elit hac vivamus, vel senectus ligula nostra dapibus ultrices accumsan est, metus a eleifend sociosqu quis urna id feugiat. Rutrum praesent vel metus accumsan fames nam libero molestie egestas ridiculus, vulputate sociis cras quisque sagittis quis scelerisque curae orci hac lobortis, ut pellentesque vehicula non nunc viverra malesuada porta torquent. Dapibus vestibulum purus ullamcorper parturient fermentum arcu phasellus netus egestas vel viverra, penatibus interdum semper dis himenaeos diam senectus justo laoreet dignissim, facilisis est aptent ante fringilla maecenas libero rhoncus praesent quam.”",
						},
					],
					layout: "L004",
					button: {
						component: "Button",
						buttonText: "Link",
						appearance: "link",
						size: "small",
						url: {
							href: "",
							linkTo: null,
							newTab: false,
							noFollow: false,
						},
					},
				},
				{
					component: "StickyFooterCTA",
					phoneNumber: "+34 91 514 53 30",
					button: {
						component: "Button",
						buttonText: "Solicita información",
						appearance: "primary",
						size: "medium",
						url: {
							href: "",
							linkTo: null,
							newTab: false,
							noFollow: false,
						},
					},
				},
			],
		},
		itemsPerPage:
			instance.includes("IPAM") || instance.includes("IADE") ? 18 : 6,
	},

	thumbnails: {},
};

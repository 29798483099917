import { instance } from "@instance-config";

import en from "./en_GB";
import es_ES from "./es_ES";
import { cegEN, cegES } from "./instances/ceg";
import { eegES } from "./instances/eeg";
import { iadePT, iadeEN } from "./instances/iade";
import { iadeEsES, iadeEsEN } from "./instances/iade-es";
import { ipamPT, ipamEN } from "./instances/ipam";
import pt_PT from "./pt_PT";

const isIADE = instance.includes("IADE");
const isEEG = instance.includes("EEG");
const isIPAM = instance.includes("IPAM");
const isCEG = instance.includes("CEG");
const isIADEES = instance.includes("iadeES");

let translationsEN;
let translationsES;
let translationsPT;

if (isCEG) {
	translationsES = { ...cegES };
	translationsEN = { ...cegEN };
} else if (isEEG) {
	translationsES = { ...eegES };
} else if (isIPAM) {
	translationsPT = { ...ipamPT };
	translationsEN = { ...ipamEN };
} else if (isIADE) {
	translationsPT = { ...iadePT };
	translationsEN = { ...iadeEN };
} else if (isIADEES) {
	translationsES = { ...iadeEsES };
	translationsEN = { ...iadeEsEN };
} else {
	translationsEN = { ...en };
	translationsES = { ...es_ES };
	translationsPT = { ...pt_PT };
}

export default {
	en: { ...translationsEN },
	es: { ...translationsES },
	pt_PT: { ...translationsPT },
};

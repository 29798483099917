import { instance } from "@instance-config";
import { schema as schemaNewsDistributor } from "@schemas/modules/NewsDistributor";
import { createSchema } from "@utils/index";

const schemaBlogDistributor = {
	schemaType: "module",
	displayName: "Blog Distributor",
	component: "BlogDistributor",
	dataPacks: ["BLOG"],
	category: "articlesAndEvents",
	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "Blog",
					type: "ReferenceField",
					sources: [{ structuredData: "BLOG" }],
					key: "data",
				},
				{
					title: "Title",
					type: "HeadingField",
					humanReadable: true,
					key: "title",
					advanced: true,
					hideable: true,
					default: { tag: "h2", content: "Title" },
					options: [
						{ value: "h1", label: "H1" },
						{ value: "h2", label: "H2" },
						{ value: "h3", label: "H3" },
						{ value: "h4", label: "H4" },
						{ value: "span", label: "span" },
					],
				},
				{
					title: "Grouping Link",
					type: "ComponentContainer",
					whiteList: ["Button"],
					key: "button",
					hideable: true,
				},
			],
		},
		{
			title: "config",
			fields: [
				{
					type: "UniqueCheck",
					key: "isAuthorHidden",
					options: [{ title: "Don't show the author" }],
				},
			],
		},
	],

	default: {
		component: "BlogDistributor",
		data: {
			mode: "auto",
			sources: [{ structuredData: "BLOG" }],
			order: "recent-ASC",
			quantity: 3,
			fullRelations: true,
		},
		hasDistributorData: true,
		isAuthorHidden: false,
		title: {
			content: "Blog distributor title",
			tag: "h2",
		},
		button: {
			component: "Button",
			buttonText: "Ir al blog",
			appearance: "link",
			size: "small",
			url: {
				href: "",
				linkTo: null,
				newTab: false,
				noFollow: false,
			},
		},
	},
};

const schema = instance.includes("CEG")
	? schemaBlogDistributor
	: schemaNewsDistributor;
export default createSchema(schema);

import CLOUDINARY_BASE_UPLOAD_URL from "@constants/cloudinary/base-upload-url";
import createThemeStyles from "@schemas/commons/getStyles";
import { createSchema } from "@utils/index";

export default createSchema({
	schemaType: "module",
	displayName: "Table",
	component: "Table",
	category: "interactive",
	dataPacks: null,
	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "Table Header Column #1",
					type: "TextField",
					key: "tabHeaderFirst",
					mandatory: false,
					hideable: false,
					humanReadable: true,
				},
				{
					title: "Table Header Column #2",
					type: "TextField",
					key: "tabHeaderSecond",
					mandatory: false,
					hideable: false,
					humanReadable: true,
				},
				{
					title: "Table Header Column #3",
					type: "TextField",
					key: "tabHeaderThird",
					mandatory: false,
					hideable: false,
					humanReadable: true,
				},
				{
					title: "Tab",
					type: "ComponentArray",
					whiteList: ["TableRow"],
					mandatory: true,
					key: "elements",
					humanReadable: true,
				},
			],
		},
		{
			title: "config",
			fields: [
				{
					title: "Style",
					key: "theme",
					type: "VisualUniqueSelection",
					mandatory: true,
					options: [
						{ ...createThemeStyles("garrigues-theme", ["default"]) },
						{ ...createThemeStyles("eeg-theme", ["defaultAlt"]) },
						{ ...createThemeStyles("ipam-theme", ["default"]) },
						{ ...createThemeStyles("iade-theme", ["default"]) },
						{ ...createThemeStyles("iade-es-theme", ["default"]) },
					],
					columns: 8,
				},
			],
		},
	],

	default: {
		component: "Table",
		tabHeaderFirst: "Lorem Ipsum",
		tabHeaderSecond: "Lorem Ipsum",
		elements: [
			{
				component: "TableRow",
				title: "Lorem ipsum",
				credits: "0",
				fileName: "pdf",
				file: "",
			},
			{
				component: "TableRow",
				title: "Lorem ipsum",
				credits: "0",
				fileName: "pdf",
				file: "",
			},
			{
				component: "TableRow",
				title: "Lorem ipsum",
				credits: "0",
				fileName: "pdf",
				file: "",
			},
		],
		theme: "default",
	},
	thumbnails: {
		"1x": `${CLOUDINARY_BASE_UPLOAD_URL}/thumbnails/Table_x2.png`,
		"2x": `${CLOUDINARY_BASE_UPLOAD_URL}/thumbnails/Table_x2.png`,
	},
});

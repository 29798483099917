import CLOUDINARY_BASE_UPLOAD_URL from "@constants/cloudinary/base-upload-url";
import { createSchema } from "@utils/index";

export default createSchema({
	schemaType: "component",
	displayName: "Button",
	component: "Button",
	dataPacks: null,
	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "Text",
					type: "TextField",
					key: "buttonText",
					mandatory: true,
					hedeable: false,
					humanReadable: true,
				},
				{
					title: "URL",
					type: "UrlField",
					key: "url",
					advanced: true,
					mandatory: false,
					hedeable: false,
				},
			],
		},
		{
			title: "config",
			fields: [
				{
					title: "Appearance",
					key: "appearance",
					type: "VisualUniqueSelection",
					options: [
						{
							theme: "garrigues-theme",
							options: [
								{
									value: "primary",
									img: `${CLOUDINARY_BASE_UPLOAD_URL}/themes/garrigues-theme/primary.png`,
								},
								{
									value: "secondary",
									img: `${CLOUDINARY_BASE_UPLOAD_URL}/themes/garrigues-theme/secondary.png`,
								},
								{
									value: "transparent",
									img: `${CLOUDINARY_BASE_UPLOAD_URL}/themes/garrigues-theme/transparent.png`,
								},
								{
									value: "link",
									img: `${CLOUDINARY_BASE_UPLOAD_URL}/themes/garrigues-theme/link.png`,
								},
							],
						},
						{
							theme: "eeg-theme",
							options: [
								{
									value: "primary",
									img: `${CLOUDINARY_BASE_UPLOAD_URL}/themes/eeg-theme/primary.png`,
								},
								{
									value: "secondary",
									img: `${CLOUDINARY_BASE_UPLOAD_URL}/themes/eeg-theme/secondary.png`,
								},
								{
									value: "transparent",
									img: `${CLOUDINARY_BASE_UPLOAD_URL}/themes/eeg-theme/transparent.png`,
								},
								{
									value: "link",
									img: `${CLOUDINARY_BASE_UPLOAD_URL}/themes/eeg-theme/link.png`,
								},
							],
						},
						{
							theme: "ipam-theme",
							options: [
								{
									value: "primary",
									img: `${CLOUDINARY_BASE_UPLOAD_URL}/themes/ipam-theme/primary.png`,
								},
								{
									value: "secondary",
									img: `${CLOUDINARY_BASE_UPLOAD_URL}/themes/ipam-theme/secondary.png`,
								},
								{
									value: "transparent",
									img: `${CLOUDINARY_BASE_UPLOAD_URL}/themes/ipam-theme/transparent.png`,
								},
								{
									value: "link",
									img: `${CLOUDINARY_BASE_UPLOAD_URL}/themes/ipam-theme/link.png`,
								},
							],
						},
						{
							theme: "iade-es-theme",
							options: [
								{
									value: "primary",
									img: `${CLOUDINARY_BASE_UPLOAD_URL}/themes/iade-es-theme/primary.png`,
								},
								{
									value: "secondary",
									img: `${CLOUDINARY_BASE_UPLOAD_URL}/themes/iade-es-theme/secondary.png`,
								},
								{
									value: "transparent",
									img: `${CLOUDINARY_BASE_UPLOAD_URL}/themes/iade-es-theme/transparent.png`,
								},
								{
									value: "link",
									img: `${CLOUDINARY_BASE_UPLOAD_URL}/themes/iade-es-theme/link.png`,
								},
							],
						},
						{
							theme: "iade-theme",
							options: [
								{
									value: "primary",
									img: `${CLOUDINARY_BASE_UPLOAD_URL}/themes/iade-theme/primary.png`,
								},
								{
									value: "secondary",
									img: `${CLOUDINARY_BASE_UPLOAD_URL}/themes/iade-theme/secondary.png`,
								},
								{
									value: "transparent",
									img: `${CLOUDINARY_BASE_UPLOAD_URL}/themes/iade-theme/transparent.png`,
								},
								{
									value: "link",
									img: `${CLOUDINARY_BASE_UPLOAD_URL}/themes/iade-theme/link.png`,
								},
							],
						},
					],
					columns: 3,
				},
			],
		},
	],

	default: {
		component: "Button",
		buttonText: "Link",
		appearance: "link",
		size: "medium",
		url: {
			href: "",
			linkTo: null,
			newTab: false,
			noFollow: false,
		},
	},
	thumbnails: {},
});

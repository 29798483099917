import CLOUDINARY_BASE_UPLOAD_URL from "@constants/cloudinary/base-upload-url";
import { instance } from "@instance-config";
import { createSchema } from "@utils/index";

const isIPAM = instance.includes("IPAM");
const isIADE = instance.includes("IADE");
const isIADEES = instance.includes("iadeES");

const defaultOptionsFilters = [
	{ value: "COURSE_MODE", name: "COURSE_MODE", title: "Course Mode" },
	{ value: "STUDY_TYPE", name: "STUDY_TYPE", title: "Program type" },
];

const extraOptionsFiltersIPAM_IADE = [
	{ value: "LOCATION", name: "LOCATION", title: "Location" },
	{ value: "LANGUAGE", name: "LANGUAGE", title: "Language" },
];

const optionsFiltersIADE = [
	...defaultOptionsFilters,
	...extraOptionsFiltersIPAM_IADE,
	{ value: "KNOWLEDGE_AREA", name: "KNOWLEDGE_AREA", title: "Knowledge Area" },
];

const optionsFiltersIPAM = [
	...defaultOptionsFilters,
	...extraOptionsFiltersIPAM_IADE,
];

const optionsFilter = () => {
	if (isIPAM) {
		return optionsFiltersIPAM;
	}
	if (isIADE || isIADEES) {
		return optionsFiltersIADE;
	}
	return defaultOptionsFilters;
};

export default createSchema({
	schemaType: "module",
	displayName: "Landings Study Distributor",
	component: "LandingStudyDistributor",
	category: "collections",
	dataPacks: ["STUDY", "EXTERNAL_STUDY"],
	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "Title",
					type: "HeadingField",
					humanReadable: true,
					key: "title",
					advanced: true,
					hideable: true,
					default: { tag: "h2", content: "Title" },
					options: [
						{ value: "h1", label: "H1" },
						{ value: "h2", label: "H2" },
						{ value: "h3", label: "H3" },
						{ value: "h4", label: "H4" },
						{ value: "span", label: "span" },
					],
				},
				{
					title: "Description",
					type: "TextField",
					humanReadable: true,
					key: "description",
					mandatory: false,
				},
				{
					title: "Filters",
					type: "CheckGroup",
					key: "filters",
					hideable: true,
					options: optionsFilter(),
				},
				{
					title: "Quick Facts",
					type: "ComponentArray",
					whiteList: ["QuickFactsLandingsPortugal"],
					key: "quickFacts",
					maxItems: 1,
					mandatory: true,
				},
				{
					title: "Items to show",
					type: "NumberField",
					key: "itemsToShow",
					mandatory: true,
				},
			],
		},
		{
			title: "config",
			fields: [
				{
					title: "Style",
					key: "theme",
					type: "VisualUniqueSelection",
					mandatory: true,
					options: [
						{
							theme: "garrigues-theme",
							options: [
								{
									value: "default",
									img: `${CLOUDINARY_BASE_UPLOAD_URL}/themes/garrigues-theme/default.png`,
								},
								{
									value: "defaultAlt",
									img: `${CLOUDINARY_BASE_UPLOAD_URL}/themes/garrigues-theme/defaultAlt.png`,
								},
							],
						},
						{
							theme: "ipam-theme",
							options: [
								{
									value: "default",
									img: `${CLOUDINARY_BASE_UPLOAD_URL}/themes/ipam-theme/default.png`,
								},
								{
									value: "defaultAlt",
									img: `${CLOUDINARY_BASE_UPLOAD_URL}/themes/ipam-theme/defaultAlt.png`,
								},
							],
						},
						{
							theme: "iade-theme",
							options: [
								{
									value: "default",
									img: `${CLOUDINARY_BASE_UPLOAD_URL}/themes/iade-theme/default.png`,
								},
								{
									value: "defaultAlt",
									img: `${CLOUDINARY_BASE_UPLOAD_URL}/themes/iade-theme/defaultAlt.png`,
								},
							],
						},
						{
							theme: "iade-es-theme",
							options: [
								{
									value: "default",
									img: `${CLOUDINARY_BASE_UPLOAD_URL}/themes/iade-es-theme/default.png`,
								},
								{
									value: "defaultAlt",
									img: `${CLOUDINARY_BASE_UPLOAD_URL}/themes/iade-es-theme/defaultAlt.png`,
								},
							],
						},
					],
					columns: 8,
				},
			],
		},
	],

	default: {
		component: "LandingStudyDistributor",
		title: { content: "Title", tag: "h2" },
		description: "Lorem Ipsum",
		quickFacts: [
			{
				component: "QuickFactsLandingsPortugal",
				data: {
					mode: "auto",
					sources: [{ structuredData: "QUICK_FACTS" }],
					order: "alpha",
				},
				hasDistributorData: true,
			},
		],
		theme: instance.includes("IPAM") ? "defaultAlt" : "default",
		filters: "",
		itemsToShow: 3,
	},
	thumbnails: {
		"1x": `${CLOUDINARY_BASE_UPLOAD_URL}/thumbnails/StudyDistributor_x2.png`,
		"2x": `${CLOUDINARY_BASE_UPLOAD_URL}/thumbnails/StudyDistributor_x2.png`,
	},
});
